var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section bg-services is-print-hidden",attrs:{"id":"occ-services"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap"},[_c('h2',{staticClass:"text-2xl md:text-4xl w-full lg:w-1/4 pt-8 lg:py-16"},[_vm._v(" Services ")]),_c('div',{staticClass:"py-6 lg:py-16 w-full lg:w-3/4 sm:grid sm:grid-rows-2 sm:grid-cols-2 lg:grid-cols-3 sm:grid-flow-row gap-4 md:gap-6 lg:gap-8"},[_c('a',{staticClass:"block bg-white p-4 lg:py-6 rounded-md mb-4 sm:mb-0 md:mb-2 lg:mb-0",attrs:{"href":"https://www.dulux.co.nz/paint-calculator","target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._m(0)]),_c('a',{staticClass:"block bg-white p-4 lg:py-6 rounded-md mb-4 sm:mb-0 md:mb-2 lg:mb-0",attrs:{"href":"https://www.dulux.co.nz/find-a-store","target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._m(1)]),_c('a',{staticClass:"block bg-white p-4 lg:py-6 rounded-md mb-4 sm:mb-0 md:mb-2 lg:mb-0",attrs:{"href":"https://www.dulux.co.nz/services/find-a-painter-nz","target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._m(2)]),_c('a',{staticClass:"block bg-white p-4 lg:py-6 rounded-md mb-4 sm:mb-0 md:mb-2 lg:mb-0",attrs:{"href":"https://www.dulux.co.nz/footer/contact-us","target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._m(3)]),_c('a',{staticClass:"block bg-white p-4 lg:py-6 rounded-md mb-4 sm:mb-0 md:mb-2 lg:mb-0",attrs:{"href":"https://www.dulux.co.nz/colour/all-colours","target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._m(4)]),_c('a',{staticClass:"block bg-white p-4 lg:py-6 rounded-md mb-4 sm:mb-0 md:mb-2 lg:mb-0",attrs:{"href":"https://www.dulux.co.nz/how-to/choose-colour/test-visualise-colour/","target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-no-wrap md:flex-wrap items-center"},[_c('div',{staticClass:"w-1/5 md:w-full"},[_c('img',{staticClass:"inline-block",attrs:{"src":require("@/assets/icons/paint-bucket.svg"),"width":"32"}})]),_c('p',{staticClass:"md:pt-5 text-lg w-4/5"},[_vm._v("Paint Calculator")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-no-wrap md:flex-wrap items-center"},[_c('div',{staticClass:"w-1/5 md:w-full"},[_c('img',{staticClass:"inline-block",attrs:{"src":require("@/assets/icons/location.svg"),"width":"24"}})]),_c('p',{staticClass:"md:pt-5 text-lg w-4/5"},[_vm._v("Find your nearest store")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-no-wrap md:flex-wrap items-center"},[_c('div',{staticClass:"w-1/5 md:w-full"},[_c('img',{staticClass:"inline-block",attrs:{"src":require("@/assets/icons/brush.svg"),"width":"28"}})]),_c('p',{staticClass:"md:pt-1 text-lg w-4/5"},[_vm._v("Find a Dulux Painter")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-no-wrap md:flex-wrap items-center"},[_c('div',{staticClass:"w-1/5 md:w-full"},[_c('img',{staticClass:"inline-block",attrs:{"src":require("@/assets/icons/speech-bubble.svg"),"width":"36"}})]),_c('p',{staticClass:"md:pt-5 text-lg w-4/5"},[_vm._v("Help & Advice")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-no-wrap md:flex-wrap items-center"},[_c('div',{staticClass:"w-1/5 md:w-full"},[_c('img',{staticClass:"inline-block",attrs:{"src":require("@/assets/icons/colourshop.svg"),"width":"32"}})]),_c('p',{staticClass:"md:pt-5 text-lg w-4/5"},[_vm._v("Dulux Colour Shop")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-no-wrap md:flex-wrap items-center"},[_c('div',{staticClass:"w-1/5 md:w-full"},[_c('img',{staticClass:"inline-block",attrs:{"src":require("@/assets/icons/visualiser.svg"),"width":"32"}})]),_c('p',{staticClass:"md:pt-5 text-lg w-4/5"},[_vm._v("How to Test Colour")])])
}]

export { render, staticRenderFns }