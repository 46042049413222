<template>
  <div :class="className">
    <div class="my-4 flex flex-wrap justify-between items-center">
      <h3 class="mb-2 text-xl">
        {{ areaName }}s<sup v-if="totalProjectAreaCount < 1" class="is-required"
          >*</sup
        >
      </h3>

      <button
        class="p-4 reorder-btn"
        :class="hideReorder ? 'hidden' : null"
        @click.prevent="toggleReorder()"
      >
        <span class="flex items-center" :class="!disabled ? 'hidden' : null">
          <Hamburger />
          <span class="ml-2">Click to reorder</span>
        </span>

        <span class="flex items-center" :class="disabled ? 'hidden' : null">
          <FinishReorder />
          <span class="ml-2">Finish reorder</span>
        </span>
      </button>
    </div>

    <draggable
      :list="projectAreas"
      class="list-group"
      tag="div"
      v-bind="dragOptions"
      :disabled="disabled"
      @start="dragging = true"
      @end="dragging = false"
    >
      <transition-group
        type="transition"
        tag="div"
        class="relative occ-bg--grey"
      >
        <div
          v-for="(projectArea, index) in projectAreas"
          :key="projectArea.id"
          class="my-2 relative list-group-item"
          :class="!disabled ? 'list-group-item--draggable' : null"
        >
          <div class="flex flex-col lg:flex-row items-end lg:items-start">
            <InputTextProjectAreaItem
              :area-type="areaType"
              area-key="area"
              :area-index="index"
              :area-value="projectArea.area"
              label="Area"
              placeholder="Area"
              class="order-2"
              :is-input-disabled="isInputDisabled"
            />

            <InputAutocompleteProjectAreaItem
              :key="0"
              :area-type="areaType"
              area-key="colour"
              :area-index="index"
              :area-value="projectArea.colour"
              label="Colour"
              placeholder="Colour"
              search="colors"
              class="order-2"
              :is-input-disabled="isInputDisabled"
            />

            <InputAutocompleteProjectAreaItem
              :key="1"
              :area-type="areaType"
              area-key="product"
              :area-index="index"
              :area-value="projectArea.product"
              label="Product/Sheen"
              placeholder="Product/Sheen"
              search="products"
              class="order-2"
              :is-input-disabled="isInputDisabled"
            />
            <div
              class="
                text-center
                mt-2
                pt-1
                outline-none
                lg:block
                remove-row-wrapper
                order-1
                lg:order-3
              "
              :class="disabled ? 'lg:mt-10' : 'lg:mt-12'"
            >
              <button
                class="p-1"
                :disabled="isInputDisabled"
                @click.prevent="confirmDelete(projectArea.id)"
              >
                <span
                  class="flex items-center"
                  :class="disabled ? null : 'hidden'"
                >
                  <icon-close class="h-8 w-8 inline-block outline-none" />
                </span>
                <span
                  class="flex items-center"
                  :class="disabled ? 'hidden' : null"
                >
                  <Hamburger />
                </span>
              </button>
            </div>
          </div>
          <div class="w-full note-wrapper">
            <InputTextProjectAreaNote
              :area-type="areaType"
              area-key="note"
              :area-index="index"
              :area-value="projectArea.note"
              label="Note (optional)"
              placeholder="Note"
              :is-input-disabled="isInputDisabled"
            />
          </div>
        </div>
      </transition-group>
    </draggable>
    <button
      class="is-button-solid flex flex-row my-4 items-center"
      @click.prevent="addRow()"
    >
      <IconPlus class="pointer-events-none mr-2" />
      Add {{ areaName }} space
    </button>

    <ConfirmationModal
      v-if="isConfirmationModalDisplayed"
      :id="selectProjectAreaId"
      @cancel="cancelDelete"
      @confirm="removeRow"
    />
  </div>
</template>

<script>
import InputTextProjectAreaItem from "@/components/form/InputTextProjectAreaItem.vue";
import InputTextProjectAreaNote from "@/components/form/InputTextProjectAreaNote.vue";
import InputAutocompleteProjectAreaItem from "@/components/form/InputAutocompleteProjectAreaItem.vue";
import IconClose from "@/components/icons/Close.vue";
import IconPlus from "@/components/icons/Plus.vue";
import Hamburger from "@/components/icons/Hamburger.vue";
import FinishReorder from "@/components/icons/FinishReorder.vue";
import draggable from "vuedraggable";
import ConfirmationModal from "@/components/form/ConfirmationModal.vue";

export default {
  components: {
    InputTextProjectAreaItem,
    InputTextProjectAreaNote,
    InputAutocompleteProjectAreaItem,
    IconClose,
    IconPlus,
    Hamburger,
    FinishReorder,
    draggable,
    ConfirmationModal,
  },
  props: {
    areaType: {
      type: String,
      required: true,
    },
    areaName: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      disabled: true,
      dragging: false,
      isConfirmationModalDisplayed: false,
      selectProjectAreaId: null,
      isInputDisabled: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 250,
        disabled: false,
        ghostClass: "ghost",
      };
    },
    projectAreas() {
      return this.$store.state.form.data[this.areaType];
    },
    hideReorder() {
      return this.projectAreas.length < 2;
    },
  },
  methods: {
    addRow() {
      this.$store.commit("form/addProjectAreaRow", { areaType: this.areaType });
    },
    confirmDelete(id) {
      this.selectProjectAreaId = id;
      this.isConfirmationModalDisplayed = true;
    },
    cancelDelete() {
      this.selectProjectAreaId = null;
      this.isConfirmationModalDisplayed = false;
    },
    removeRow() {
      const id = this.selectProjectAreaId;
      this.$store.commit("form/removeProjectAreaRow", {
        areaType: this.areaType,
        id,
      });
      this.isConfirmationModalDisplayed = false;
      this.selectProjectAreaId = null;
    },
    toggleReorder() {
      if (this.disabled) {
        this.isInputDisabled = true;
      } else {
        this.isInputDisabled = false;
      }

      this.disabled = !this.disabled;
    },
  },
};
</script>
<style scoped>
.ghost {
  opacity: 0.75;
  border: 1px solid #e5e5e5;
  background: #e5e5e5;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.5);
}

.ghost * {
  opacity: 0;
}

.reorder-btn {
  background: rgba(219, 219, 219, 1);
  transition: all 0.4s;
}
.reorder-btn:hover {
  background: rgba(190, 190, 190, 1);
}
</style>

<style>
.list-group-item--draggable,
.list-group-item--draggable .block.my-2 {
  cursor: move;
}
.list-group-item--draggable input,
.list-group-item--draggable .remove-row-wrapper,
.list-group-item--draggable .note-wrapper {
  pointer-events: none;
}

input.is-input:disabled {
  background-color: white !important;
}
</style>
