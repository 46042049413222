<template>
  <div class="w-full lg:p-1 lg:-ml-1">
    <validation-provider v-slot="{ errors }" rules="required">
      <label class="block my-2"
        >{{ label }}<sup class="is-required">*</sup></label
      >
      <input
        v-model="computedAreaValue"
        class="is-input"
        type="text"
        :placeholder="placeholder"
        :disabled="isInputDisabled"
      />
      <div class="is-error">{{ errors[0] }}</div>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  components: {
    ValidationProvider,
  },
  props: {
    areaType: {
      type: String,
      default: null,
    },
    areaKey: {
      type: String,
      required: true,
    },
    areaIndex: {
      type: Number,
      required: true,
    },
    areaValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    isInputDisabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    computedAreaValue: {
      get() {
        return this.areaValue;
      },
      set(input) {
        this.$store.commit("form/updateProjectAreaItem", {
          areaType: this.areaType,
          areaKey: this.areaKey,
          areaIndex: this.areaIndex,
          areaValue: input.length === 1 ? input.toUpperCase() : input,
        });
      },
    },
  },
};
</script>
