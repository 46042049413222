var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section bg-recommended is-print-hidden",attrs:{"id":"occ-recommended"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"py-6 lg:py-16 w-full"},[_c('h2',{staticClass:"text-2xl md:text-4xl"},[_vm._v("Recommended for you")]),_c('div',{staticClass:"sm:grid sm:grid-rows-2 lg:grid-rows-1 md:grid-cols-2 lg:grid-cols-4 sm:grid-flow-row gap-4 lg:gap-8 mt-8"},[_c('div',{staticClass:"mb-6 sm:mb-0 bg-white"},[_c('a',{staticClass:"block relative overflow-hidden pb-2/3",attrs:{"href":_vm.$isRegion('au')
                ? 'https://www.dulux.com.au/services/find-a-painter'
                : 'https://www.dulux.co.nz/services/find-a-painter-nz',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_c('img',{staticClass:"absolute h-full w-full object-cover",attrs:{"src":"/images/links/image1.jpg","alt":""}})]),_c('p',{staticClass:"p-6 text-md font-light"},[_vm._v(" "+_vm._s(_vm.$isRegion("au") ? "Dulux Accredited Painters. Trusted by Australia's most trusted paint brand." : "Dulux Accredited Painters. Trusted by Dulux New Zealand.")+" "),_c('a',{staticClass:"block underline pt-8 sm:pt-4",attrs:{"href":_vm.$isRegion('au')
                  ? 'https://www.dulux.com.au/services/find-a-painter'
                  : 'https://www.dulux.co.nz/services/find-a-painter-nz',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._v("Read more")])])]),_c('div',{staticClass:"mb-6 sm:mb-0 bg-white"},[_c('a',{staticClass:"block relative overflow-hidden pb-2/3",attrs:{"href":_vm.$isRegion('au')
                ? _vm.linkAtBase('/colour/colour-trends/2024')
                : 'https://www.dulux.co.nz/colour/colour-trends/2024/',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_c('img',{staticClass:"absolute h-full w-full object-cover",attrs:{"src":"/images/links/image2.jpg","alt":""}})]),_c('p',{staticClass:"p-6 text-md font-light"},[_vm._v(" Explore the 2024 Dulux Colour Forecast colour palettes. Discover the trends set to influence colour and design for the coming year. "),_c('a',{staticClass:"block underline pt-8 sm:pt-4",attrs:{"href":_vm.$isRegion('au')
                  ? _vm.linkAtBase('/colour/colour-trends/2024')
                  : 'https://www.dulux.co.nz/colour/colour-trends/2024',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._v("Read more")])])]),_c('div',{staticClass:"mb-6 sm:mb-0 bg-white"},[_c('a',{staticClass:"block relative overflow-hidden pb-2/3",attrs:{"href":_vm.$isRegion('au')
                ? _vm.linkAtBase('/how-to/preparation-and-painting-guides')
                : 'https://www.dulux.co.nz/how-to/overview',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_c('img',{staticClass:"absolute h-full w-full object-cover",attrs:{"src":"/images/links/image3.jpg","alt":""}})]),_c('p',{staticClass:"p-6 text-md font-light"},[_vm._v(" This collection of Dulux painting guides will help you get the right tips and techniques for your next project. "),_c('a',{staticClass:"block underline pt-8 sm:pt-4",attrs:{"href":_vm.$isRegion('au')
                  ? _vm.linkAtBase('/how-to/preparation-and-painting-guides')
                  : 'https://www.dulux.co.nz/how-to/overview',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._v("Read more")])])]),_c('div',{staticClass:"mb-6 sm:mb-0 bg-white"},[_c('a',{staticClass:"block relative overflow-hidden pb-2/3",attrs:{"href":_vm.$isRegion('au')
                ? _vm.linkAtBase('/inspiration/share-your-diy-home-projects')
                : 'https://www.dulux.co.nz/inspiration/share-your-diy-home-projects',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_c('img',{staticClass:"absolute h-full w-full object-cover",attrs:{"src":"/images/links/image5.jpg","alt":""}})]),_c('p',{staticClass:"p-6 text-md font-light"},[_vm._v(" Explore our different Project Guides for advice on completing those projects you’ve been meaning to do. "),_c('a',{staticClass:"block underline pt-8 sm:pt-4",attrs:{"href":_vm.$isRegion('au')
                  ? _vm.linkAtBase('/inspiration/share-your-diy-home-projects')
                  : 'https://www.dulux.co.nz/how-to/project-guides',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._v("Read more")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }