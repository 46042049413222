<template>
  <div class="area-row__item">
    <div class="area-row__label">Colour</div>
    <div v-if="colorData" class="area-row__color">
      <a :href="colourUrl" target="_blank" @click="(e) => $outbound(e)">
        <ColourSwatch :color="colorData.hex" />
      </a>
    </div>
    <div class="area-row__text">
      <a
        v-if="colourUrl"
        class="is-link print__no_underlined"
        :href="colourUrl"
        target="_blank"
        @click="(e) => $outbound(e)"
        >{{ colorName }}</a
      >
      <span v-if="!colourUrl">{{ colorName }}</span>
    </div>
  </div>
</template>

<script>
import autocomplete from "@/data/web/index.js";
import ColourSwatch from "@/components/icons/ColourSwatch.vue";
import { isRegion } from "@/lib/region";
import { defaultColourUrl, searchColourUrl } from "@/lib/links";

export default {
  components: {
    ColourSwatch,
  },
  props: {
    colorName: {
      type: String,
      required: true,
    },
  },
  computed: {
    colorData() {
      return (
        autocomplete.colors.find((color) => color.name === this.colorName) ||
        null
      );
    },
    colourUrl() {
      // todo: use the colour data url when data is updated
      if (isRegion("au")) return searchColourUrl(this.colorName);

      return (this.colorData && this.colorData.url) ?? null;
    },

    fallbackUrl() {
      return defaultColourUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
// Print styles
@media print {
  .print__no_underlined {
    @apply no-underline;
  }
}
</style>
