import { isRegion } from "./region";

/**
 *
 * @returns string
 */
const getRegion = () => (process.env.VUE_APP_REGION || "au").toLowerCase();

const baseUrlAu = "https://www.dulux.com.au";
// const baseUrlAuBeta = "https://beta.dulux.com.au";
const baseUrlNz = "https://www.dulux.co.nz";

/**
 *
 * @param {string} slug
 * @returns {string}
 */
export const linkAtBase = (slug) => {
  if (!slug.startsWith("/")) slug = "/" + slug;
  return getRegion() === "au" ? `${baseUrlAu}${slug}` : `${baseUrlNz}${slug}`;
};

export const defaultColourUrl = isRegion("au") ? linkAtBase("/colours") : null;

/**
 *
 * @param {string} colourName
 * @returns {string|null}
 *  */
export const searchColourUrl = (colourName) => {
  if (!isRegion("au")) return null;

  let url = new URL("search", baseUrlAu);
  url.searchParams.append("q", colourName);
  return url.toString();
};

/**
 *
 * @param {string} link
 * @returns
 */
export const forceBeta = (link) => link.replace("www.", "beta.");

/**
 *
 * @param {string} link
 * @returns string
 */
export const removeBeta = (link) => link.replace("beta.", "www.");
