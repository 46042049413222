<template>
  <div class="mt-10">
    <label class="block mb-2 text-lg">General Project Comments</label>
    <textarea
      ref="textarea"
      v-model="comments"
      class="is-textarea h-56 max-h-full"
    />
  </div>
</template>
<script>
import debounce from "lodash.debounce";
export default {
  computed: {
    comments: {
      get() {
        return this.$store.state.form.data.comments;
      },
      set: debounce(function (value) {
        this.$store.commit("form/setComments", value);
      }, 150),
    },
  },
};
</script>
