<template>
  <div id="occ-recommended" class="section bg-recommended is-print-hidden">
    <div class="container">
      <div class="py-6 lg:py-16 w-full">
        <h2 class="text-2xl md:text-4xl">Recommended for you</h2>
        <div
          class="
            sm:grid sm:grid-rows-2
            lg:grid-rows-1
            md:grid-cols-2
            lg:grid-cols-4
            sm:grid-flow-row
            gap-4
            lg:gap-8
            mt-8
          "
        >
          <div class="mb-6 sm:mb-0 bg-white">
            <a
              :href="
                $isRegion('au')
                  ? 'https://www.dulux.com.au/services/find-a-painter'
                  : 'https://www.dulux.co.nz/services/find-a-painter-nz'
              "
              target="_blank"
              class="block relative overflow-hidden pb-2/3"
              @click="(e) => $outbound(e)"
            >
              <img
                src="/images/links/image1.jpg"
                alt=""
                class="absolute h-full w-full object-cover"
              />
            </a>
            <p class="p-6 text-md font-light">
              {{
                $isRegion("au")
                  ? "Dulux Accredited Painters. Trusted by Australia's most trusted paint brand."
                  : "Dulux Accredited Painters. Trusted by Dulux New Zealand."
              }}
              <a
                :href="
                  $isRegion('au')
                    ? 'https://www.dulux.com.au/services/find-a-painter'
                    : 'https://www.dulux.co.nz/services/find-a-painter-nz'
                "
                target="_blank"
                class="block underline pt-8 sm:pt-4"
                @click="(e) => $outbound(e)"
                >Read more</a
              >
            </p>
          </div>
          <div class="mb-6 sm:mb-0 bg-white">
            <a
              :href="
                $isRegion('au')
                  ? linkAtBase('/colour/colour-trends/2024')
                  : 'https://www.dulux.co.nz/colour/colour-trends/2024/'
              "
              target="_blank"
              class="block relative overflow-hidden pb-2/3"
              @click="(e) => $outbound(e)"
            >
              <img
                src="/images/links/image2.jpg"
                alt=""
                class="absolute h-full w-full object-cover"
              />
            </a>
            <p class="p-6 text-md font-light">
              Explore the 2024 Dulux Colour Forecast colour palettes. Discover
              the trends set to influence colour and design for the coming year.
              <a
                :href="
                  $isRegion('au')
                    ? linkAtBase('/colour/colour-trends/2024')
                    : 'https://www.dulux.co.nz/colour/colour-trends/2024'
                "
                target="_blank"
                class="block underline pt-8 sm:pt-4"
                @click="(e) => $outbound(e)"
                >Read more</a
              >
            </p>
          </div>
          <div class="mb-6 sm:mb-0 bg-white">
            <a
              :href="
                $isRegion('au')
                  ? linkAtBase('/how-to/preparation-and-painting-guides')
                  : 'https://www.dulux.co.nz/how-to/overview'
              "
              target="_blank"
              class="block relative overflow-hidden pb-2/3"
              @click="(e) => $outbound(e)"
            >
              <img
                src="/images/links/image3.jpg"
                alt=""
                class="absolute h-full w-full object-cover"
              />
            </a>
            <p class="p-6 text-md font-light">
              This collection of Dulux painting guides will help you get the
              right tips and techniques for your next project.
              <a
                :href="
                  $isRegion('au')
                    ? linkAtBase('/how-to/preparation-and-painting-guides')
                    : 'https://www.dulux.co.nz/how-to/overview'
                "
                target="_blank"
                class="block underline pt-8 sm:pt-4"
                @click="(e) => $outbound(e)"
                >Read more</a
              >
            </p>
          </div>
          <div class="mb-6 sm:mb-0 bg-white">
            <a
              :href="
                $isRegion('au')
                  ? linkAtBase('/inspiration/share-your-diy-home-projects')
                  : 'https://www.dulux.co.nz/inspiration/share-your-diy-home-projects'
              "
              target="_blank"
              class="block relative overflow-hidden pb-2/3"
              @click="(e) => $outbound(e)"
            >
              <img
                src="/images/links/image5.jpg"
                alt=""
                class="absolute h-full w-full object-cover"
              />
            </a>
            <p class="p-6 text-md font-light">
              Explore our different Project Guides for advice on completing
              those projects you’ve been meaning to do.
              <a
                :href="
                  $isRegion('au')
                    ? linkAtBase('/inspiration/share-your-diy-home-projects')
                    : 'https://www.dulux.co.nz/how-to/project-guides'
                "
                target="_blank"
                class="block underline pt-8 sm:pt-4"
                @click="(e) => $outbound(e)"
                >Read more</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { linkAtBase } from "@/lib/links";

export default {
  methods: {
    linkAtBase,
  },
};
</script>
