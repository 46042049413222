<template>
  <div class="container mx-auto pb-10 pt-10 lg:pb-16">
    <div class="w-full md:max-w-xl">
      <h3 class="font-bold mb-2">Designer</h3>
      <div v-if="designer_name" class="block md:flex mb-2">
        <div class="w-full md:w-1/3 text-lg">Name</div>
        <div class="w-full md:w-2/3" data-test="designer name">
          {{ designer_name }}
        </div>
      </div>
      <div v-if="designer_email" class="block md:flex mb-2">
        <div class="w-full md:w-1/3 text-lg occ-overflow-wrap">Email</div>
        <div class="w-full md:w-2/3">
          {{ designer_email }}
        </div>
      </div>
      <div v-if="dealId" class="block md:flex">
        <div class="w-full md:w-1/3 text-lg occ-overflow-wrap">Deal ID</div>
        <div class="w-full md:w-2/3">
          <pre>{{ dealId }}</pre>
        </div>
      </div>
    </div>
    <div class="w-full md:max-w-xl mt-8">
      <h3 class="font-bold mb-2">Customer</h3>
      <div
        v-if="contact_firstname || contact_lastname"
        class="block md:flex mb-2"
      >
        <div class="w-full md:w-1/3 text-lg">Name</div>
        <div class="w-full md:w-2/3">
          {{ contact_firstname }} {{ contact_lastname }}
        </div>
      </div>
      <div v-if="contact_phone" class="block md:flex mb-2">
        <div class="w-full md:w-1/3 text-lg">Contact Number</div>
        <div class="w-full md:w-2/3">
          {{ contact_phone }}
        </div>
      </div>
      <div v-if="contact_email" class="block md:flex">
        <div class="w-full md:w-1/3 text-lg occ-overflow-wrap">Email</div>
        <div class="w-full md:w-2/3">
          {{ contact_email }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dealId: {
      type: String,
      default: null,
    },
    designer_name: {
      type: String,
      default: null,
    },
    designer_email: {
      type: String,
      default: null,
    },
    contact_firstname: {
      type: String,
      default: null,
    },
    contact_lastname: {
      type: String,
      default: null,
    },
    contact_email: {
      type: String,
      default: null,
    },
    contact_phone: {
      type: String,
      default: null,
    },
  },
};
</script>
