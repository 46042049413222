<template>
  <div class="w-full lg:p-1">
    <div ref="inputWrapper" class="relative">
      <validation-provider v-slot="{ errors }" rules="required">
        <label class="block my-2"
          >{{ label }}<sup class="is-required">*</sup></label
        >
        <input
          ref="input"
          v-model="computedAreaValue"
          type="text"
          class="is-input"
          :placeholder="label"
          :disabled="isInputDisabled"
          @focus="handleInputFocused"
        />
        <div class="is-error pb-2">{{ errors[0] }}</div>
      </validation-provider>
      <div
        v-if="displayResults"
        ref="results"
        class="absolute bg-white border rounded w-full p-1 shadow-md z-10"
      >
        <button
          v-for="({ obj: result }, index) in results"
          :key="areaValue + label + result.name + index"
          tabindex="-1"
          class="
            w-full
            border-0
            focus:vc-shadow
            p-1
            text-gray-700
            leading-tight
            hover:bg-gray-100
            focus:bg-gray-300
            text-left
            flex
            items-center
          "
          @focus="handleResultFocused(index)"
          @click.prevent="setChosenResult(result.name)"
        >
          <span
            v-if="result.hex"
            class="rounded inline-block mr-2"
            :style="{ width: `26px`, height: `26px`, background: result.hex }"
          ></span>
          <span>
            {{ result.name }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import autocomplete from "@/data/web/index.js";
import fuzzysort from "fuzzysort";
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  components: {
    ValidationProvider,
  },
  props: {
    areaType: {
      type: String,
      default: null,
    },
    areaKey: {
      type: String,
      required: true,
    },
    areaIndex: {
      type: Number,
      required: true,
    },
    areaValue: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      required: true,
      validator: function (value) {
        return ["colors", "products"].indexOf(value) !== -1;
      },
    },
    label: {
      type: String,
      required: true,
    },
    isInputDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      results: [],
      keys: ["color"],
      isActive: false,
      focusIndex: null,
      isChosenResult: false,
    };
  },
  computed: {
    displayResults() {
      return this.results.length > 0 && !this.isChosenResult && this.isActive;
    },

    computedAreaValue: {
      get() {
        return `${this.areaValue}`;
      },
      set(input) {
        this.setInputValue(input);
        const isValidQuery = !this.isChosenResult && input.length >= 2;
        this.isChosenResult = false;
        this.results = isValidQuery
          ? fuzzysort.go(input, autocomplete[this.search], {
              keys: ["name"],
              threshold: -Infinity,
              limit: 20,
              allowTypo: true,
            })
          : [];
      },
    },
  },
  watch: {
    isActive(isActive) {
      isActive
        ? document.addEventListener("keydown", this.handleKey)
        : document.removeEventListener("keydown", this.handleKey);
    },

    results(results) {
      if (results.length > 0) {
        document.addEventListener("focusin", this.handleFocus);
        document.addEventListener("mousedown", this.handleMouseDown);
      } else {
        document.removeEventListener("focusin", this.handleFocus);
        document.removeEventListener("mousedown", this.handleMouseDown);
      }
    },

    focusIndex(focusIndex) {
      // if (focusIndex === null) {
      //   this.$refs.input.focus();
      // }
      if (Number.isInteger(focusIndex)) {
        this.$nextTick(() => {
          this.$refs.results &&
            this.$refs.results.children &&
            this.$refs.results.children[focusIndex] &&
            this.$refs.results.children[focusIndex].focus();
        });
      }
    },
  },

  beforeDestroy() {
    document.removeEventListener("focusin", this.handleFocus);
    document.removeEventListener("mousedown", this.handleMouseDown);
    document.removeEventListener("keydown", this.handleKey);
  },

  methods: {
    setInputValue(value) {
      this.$store.commit("form/updateProjectAreaItem", {
        areaType: this.areaType,
        areaKey: this.areaKey,
        areaIndex: this.areaIndex,
        areaValue: value,
      });
    },
    // Handle arrow up and down dropdown list items
    handleKey(e) {
      if (e.code === "ArrowDown") {
        e.preventDefault();
        this.focusIndex = Number.isInteger(this.focusIndex)
          ? Math.min(this.focusIndex + 1, this.results.length - 1)
          : 0;
      }
      if (e.code === "ArrowUp") {
        e.preventDefault();
        // console.log("arrowup", this.focusIndex);
        this.focusIndex =
          Number.isInteger(this.focusIndex) && this.focusIndex !== 0
            ? Math.max(this.focusIndex - 1, 0)
            : null;
      }
    },

    handleMouseDown({ target }) {
      // Disable dropdown if click outside
      if (!this.$refs.inputWrapper.contains(target)) {
        this.isActive = false;
      }
    },

    handleFocus() {
      // Disable dropdown if focus out
      if (!this.$refs.inputWrapper.contains(document.activeElement)) {
        this.isActive = false;
      }
    },

    setChosenResult(result) {
      this.$refs.input.focus();
      this.isChosenResult = true;
      this.setInputValue(result);
      this.results = [];
    },

    handleInputFocused() {
      this.isActive = true;
      this.focusIndex = null;
    },

    handleResultFocused(index) {
      this.isActive = true;
      this.focusIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.is-input {
  @apply appearance-none;
  @apply border;
  @apply leading-tight;
  @apply mb-2;
  @apply px-3;
  @apply py-2;
  @apply rounded-none;
  @apply text-gray-700;
  @apply w-full;
  &:focus {
    @apply outline-none;
    @apply shadow-outline;
  }
}
</style>
