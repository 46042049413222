<template>
  <nav class="z-10 border-b border-gray-400 shadow-md nav is-print-hidden">
    <div class="container items-center block md:flex">
      <h1 class="hidden pb-3 text-xl md:block occ-text--primary md:pb-0">
        Preview: Colour Consultation Summary
      </h1>
      <h1 class="block pb-3 text-base md:hidden occ-text--primary md:pb-0">
        Preview mode
      </h1>
      <div class="flex content-between buttons md:block">
        <button
          :disabled="isSubmitting"
          class="
            w-full
            mr-2
            text-xs
            md:mr-0
            md:w-40
            md:text-base
            occ-button
            is-button-outline
          "
          @click.prevent="$store.commit('togglePreviewMode')"
        >
          Edit form
        </button>
        <button
          :disabled="isSubmitting"
          class="
            inline-flex
            justify-center
            w-full
            ml-0
            text-xs
            is-button-solid
            md:ml-2
            md:w-48
            md:text-base
            occ-button occ-button--primary
          "
          @click.prevent="checkNetworkAndSubmit"
        >
          <svg
            v-if="isSubmitting"
            class="w-5 h-5 mr-3 text-white occ-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {{ isEditing ? "Update Report" : "Submit" }}
        </button>
      </div>
    </div>
    <div class="container items-center block">
      <div v-if="errors" class="border border-red-600 rounded mt-2 m-1 p-2">
        <div v-for="error in errors" :key="error">
          <div v-for="message in error" :key="message">{{ message }}</div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    isEditing() {
      return this.$store.state.form.data.editing || false;
    },
    errorModalStatus() {
      return this.$store.state.errorNetworkModalDisplayed;
    },
    network() {
      return this.$store.getters["form/network"];
    },
    reportNetwork() {
      return this.$store.getters["form/reportNetwork"];
    },
  },
  watch: {
    errorModalStatus(value) {
      this.isSubmitting = value;
    },
    reportNetwork(value) {
      if (!value.submitting) {
        this.isSubmitting = false;
      }

      if (value.error) {
        this.errors = value.errors;
      }
    },
  },
  created() {
    this.$store.commit("setBypassPreflightCheck", false);
    this.$store.commit("setErrorNetworkModalDisplayed", false);
    this.$store.commit("form/setUnstableNetwork", false);
  },
  methods: {
    checkNetworkAndSubmit() {
      if (!navigator.onLine) {
        // if there is no internet
        this.isSubmitting = true;
        this.errors = undefined;

        if (!this.errorModalStatus) {
          this.$store.commit("setErrorNetworkModalDisplayed", true);
        }
      } else {
        this.isSubmitting = true;
        this.errors = undefined;

        if (this.isEditing) {
          this.$store.dispatch("form/updateReport", {
            deal_id: this.$route.query.deal_id || null,
            contact_id: this.$route.query.contact_id || null,
            edit_id: this.$route.query.edit_id || null,
          });
        } else {
          this.$store.dispatch("form/createReport");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  @apply sticky;
  @apply top-0;
  @apply bg-white;
  @apply py-4;
  align-items: center;
}

.buttons {
  @apply ml-auto;
}
</style>
