<template>
  <div class="is-section bg-figma-3 print__no-break print__no-bg">
    <div class="container">
      <div class="columns">
        <div>
          <h2 class="text-2xl md:text-4xl">Notes</h2>
        </div>
        <div>
          <p v-if="comments" class="mb-3 whitespace-pre-wrap">{{ comments }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comments: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins.scss";

.columns {
  @include md {
    @apply grid;
    @apply grid-cols-2;
    @apply gap-8;
  }
}

// Print styles
@media print {
  .print__no-bg {
    background: none;

    h2 {
      padding-bottom: 10pt;
    }
  }
}
</style>
