<template>
  <div class="section-greeting bg-figma-5 print__no-break print__no-bg">
    <div class="container container--greeting-md">
      <div class="columns">
        <div class="columns__left">
          <div class="container container--greeting-sm">
            <p class="text-xl">
              Thanks so much for sharing your project with us. We hope you came
              away with helpful colour ideas to suit your space and personal
              style. Here’s your consultation summary for reference to complete
              your colour transformation.
            </p>
          </div>
        </div>
        <div class="columns__right">
          <div v-if="intro" class="container container--greeting-sm">
            <p class="mb-3 whitespace-pre-wrap">{{ intro }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    designerName: {
      type: String,
      default: null,
    },
    contactFirstname: {
      type: String,
      default: null,
    },
    intro: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins.scss";

.section-greeting {
  background: linear-gradient(to right, $color-figma-5 50%, $color-figma-2 50%);
  @include sm {
    @apply py-6;
  }
  @include md {
    @apply py-10;
  }
  @include lg {
    @apply py-16;
  }
}

.container--greeting-md {
  @include lt-sm {
    @apply max-w-none;
    @apply p-0;
  }
}
.container--greeting-sm {
  @include sm {
    @apply p-0;
  }
}

.columns {
  @include sm {
    @apply grid;
    @apply grid-cols-2;
    @apply gap-12;
  }
  @include lg {
    @apply gap-16;
  }
  @include xl {
    @apply gap-20;
  }
}

.columns__left {
  @apply bg-figma-5;
  @include lt-sm {
    @apply py-8;
  }
}

.columns__right {
  @apply bg-figma-2;
  @include lt-sm {
    @apply py-8;
  }
}

.title {
  @apply mb-4;
  @include md {
    @apply mb-8;
  }
}

.text {
  @apply mb-4;
  max-width: 500px;
  @include md {
    @apply mb-8;
  }
}

.file {
  @apply flex;
  @apply py-1;
}

.file__icon {
  @apply mr-4;
}

.file__text {
  @apply underline;
  @include md {
    @apply text-lg;
  }
}

// Print styles
@media print {
  .print__no-bg {
    background: none;

    .columns__left {
      background-color: transparent;
      padding-bottom: 0;
    }

    .columns__right {
      background-color: transparent;
    }
  }
}
</style>
