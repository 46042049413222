<template>
  <div class="is-section bg-figma-3 print__no-bg">
    <div class="container header">
      <div class="left">
        <header class="flex flex-col h-full mb-8 lg:mb-0 print__flex-row">
          <div class="mb-8 print__col-1">
            <img
              v-if="$isRegion('nz')"
              src="@/assets/icons/dulux-nz-logo.png"
              class="w-32 md:w-48 print__ml-auto"
            />
            <img
              v-else
              src="@/assets/icons/dulux-logo.svg"
              class="w-32 md:w-48 print__ml-auto"
            />
            <a
              class="file hidden print__file mt-2"
              :href="reportUrl"
              target="_blank"
              @click.prevent="printPdf"
            >
              <span class="inline-flex print__flex text-sm justify-end">
                <span class="file__text print__file__text">
                  View online report
                </span>
              </span>
            </a>
          </div>
          <div class="mt-auto print__col-2 print__mt-0">
            <h1 class="is-h1">Colour <br />Consultation <br />Summary</h1>
          </div>
        </header>
      </div>

      <div class="right">
        <div class="is-table-details">
          <div v-if="contact_fullname" class="is-table-details__row">
            <div class="is-table-details__cell font-medium">Client Name</div>
            <div class="is-table-details__cell">{{ contact_fullname }}</div>
          </div>
          <div v-if="contact_phone" class="is-table-details__row">
            <div class="is-table-details__cell font-medium">Contact Number</div>
            <div class="is-table-details__cell">
              {{ contact_phone }}
            </div>
          </div>
          <div v-if="address" class="is-table-details__row">
            <div class="is-table-details__cell font-medium">Address</div>
            <div class="is-table-details__cell">
              {{ address }}
            </div>
          </div>
          <div v-if="contact_email" class="is-table-details__row">
            <div class="is-table-details__cell font-medium">Email</div>
            <div class="is-table-details__cell">
              {{ contact_email }}
            </div>
          </div>
          <div v-if="consultation_date" class="is-table-details__row">
            <div class="is-table-details__cell font-medium border-none">
              Date of Consultation
            </div>
            <div class="is-table-details__cell border-none">
              {{ formattedDate }}
            </div>
          </div>
        </div>

        <div class="is-table-details">
          <div v-if="designer_name" class="is-table-details__row">
            <div class="is-table-details__cell font-medium">
              Dulux Colour Designer
            </div>
            <div class="is-table-details__cell" data-test="designer name">
              {{ designer_name }}
            </div>
          </div>
          <div v-if="designer_phone" class="is-table-details__row">
            <div class="is-table-details__cell font-medium">Contact Number</div>
            <div class="is-table-details__cell">
              {{ designer_phone }}
            </div>
          </div>
          <div v-if="designer_email" class="is-table-details__row">
            <div class="is-table-details__cell font-medium">Email</div>
            <div class="is-table-details__cell">
              {{ designer_email }}
            </div>
          </div>
        </div>

        <div v-if="reportUrl" class="pt-4 print__file-wrapper">
          <a
            class="file is-print-hidden"
            :href="reportUrl"
            target="_blank"
            @click.prevent="printPdf"
          >
            <span class="inline-flex print__flex">
              <span class="file__icon">
                <IconDownload />
              </span>
              <span class="file__text puppeteer__hidden">
                Download print-friendly PDF
              </span>
              <span
                class="file__text print__file__text hidden puppeteer__shown"
              >
                View online report
              </span>
            </span>
          </a>
        </div>

        <div v-if="pdfDownloadUrl" class="pt-4 puppeteer__hidden">
          <a
            class="file is-print-hidden"
            :href="pdfDownloadUrl"
            target="_blank"
            @click="(e) => $outbound(e)"
          >
            <span class="inline-flex print__flex">
              <span class="file__icon">
                <IconDownload />
              </span>
              <span class="file__text"> Download PDF </span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "date-fns";
import IconDownload from "@/components/icons/Download";

export default {
  components: {
    IconDownload,
  },
  props: {
    designer_name: {
      type: String,
      default: null,
    },
    designer_phone: {
      type: String,
      default: null,
    },
    designer_email: {
      type: String,
      default: null,
    },
    contact_fullname: {
      type: String,
      default: null,
    },
    contact_phone: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
    contact_email: {
      type: String,
      default: null,
    },
    consultation_date: {
      type: [Date, String],
      default: null,
    },
  },
  data() {
    return {
      primaryTab: true,
    };
  },
  computed: {
    pdfDownloadUrl() {
      return this.$store.state.report.reportId
        ? `${process.env.VUE_APP_PDF_DOWNLOAD_URL}${
            this.$store.state.report.reportId
          }&name=${this.contact_fullname.replace(/\s/g, "")}&date=${format(
            new Date(),
            "yyyyMMdd"
          )}`
        : null;
    },
    reportUrl() {
      return this.$store.state.report.reportId
        ? `${window.location.origin}${this.$route.path}`
        : null;
    },
    formattedDate() {
      return format(new Date(this.consultation_date), "dd/MM/yyyy");
    },
  },
  methods: {
    printPdf() {
      // YYYYMMDD_DCDSColourReport_[Client First Last Name]_[ReportID]_print.PDF
      const filename =
        format(new Date(), "yyyyMMdd") +
        "_DCDSColourReport_" +
        this.contact_fullname.replace(/\s/g, "") +
        "_" +
        this.$store.state.report.reportId +
        "_print.pdf";

      // console.log(filename);
      document.title = filename;
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins.scss";

.header {
  @include md {
    @apply grid;
    @apply grid-cols-2;
    @apply gap-8;
  }
}

.file {
  @apply py-1;
}

.file__icon {
  @apply mr-4;
}

.file__text {
  @apply underline;
  @apply text-blue-700;
  @include md {
    @apply text-lg;
  }
}

// Print styles
@media print {
  .print__no-bg {
    background-color: transparent;
  }

  .print__flex-row {
    @apply flex-row;
    @apply justify-between;
  }

  .print__col-1 {
    order: 2;
  }

  .print__col-2 {
    order: 1;
  }

  .print__file__text {
    @apply inline;
  }

  .file::after {
    content: " (" attr(href) ") ";
    font-size: small;
  }

  .print__flex {
    @apply flex;
  }

  .print__file {
    @apply flex flex-col;
  }

  .print__ml-auto {
    @apply ml-auto;
  }

  .print__mt-0 {
    @apply mt-0;
  }
}
</style>
