<template>
  <div class="w-full my-10">
    <validation-provider v-slot="{ errors }" rules="required">
      <label class="block my-2 text-lg">
        Add your personal thank you message<sup class="is-required">*</sup>
      </label>
      <textarea
        ref="textarea"
        v-model="intro"
        class="is-textarea h-56 max-h-full"
      />
      <div class="is-error pb-2">{{ errors[0] }}</div>
    </validation-provider>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  computed: {
    intro: {
      get() {
        return this.$store.state.form.data.intro;
      },
      set(value) {
        this.$store.commit("form/setIntro", value);
      },
    },
  },
};
</script>
