import api from "@/api";

export default {
  state: {
    network: {
      loading: false,
      error: false,
      errorMessage: null,
    },
    data: null,
    redirectRequested: null,
  },

  getters: {
    network(state) {
      return state.network;
    },

    data(state) {
      return state.data;
    },

    redirectRequested(state) {
      return state.redirectRequested;
    },

    formPayload(state) {
      return (
        state.data && {
          deal_id: state.data.deal_id,
          contact_id: state.data.contact_id,
        }
      );
    },
  },

  mutations: {
    setNetwork(state, payload) {
      state.network = payload;
    },

    setData(state, data) {
      state.data = data;
    },

    setRedirectRequested(state, redirectRequested) {
      state.redirectRequested = redirectRequested;
    },

    resetData(state) {
      state.data = null;
    },
  },

  actions: {
    async getById({ commit }, { dealId, contactId, editId }) {
      if (Number(dealId) && Number(contactId)) {
        try {
          commit("setNetwork", {
            loading: true,
            error: false,
            errorMessage: null,
          });
          commit("setRedirectRequested", null);
          const { data } = await api.get(
            `/deals/${dealId}/${contactId}/${editId || ""}`
          );
          // Check if the response indicates we should redirect
          if (data.status && data.redirect) {
            commit("setRedirectRequested", data.redirect);
            commit("setNetwork", {
              loading: false,
              error: false,
              errorMessage: null,
            });
            return;
          }
          // Check for and handle synthetic status codes
          if (data.status && data.status !== 200) {
            throw new Error(`${data.status} / ${data.message}`);
          }
          commit("setNetwork", {
            loading: false,
            error: false,
            errorMessage: null,
          });
          commit("setData", data);
        } catch ({ message }) {
          commit("setNetwork", {
            loading: false,
            error: true,
            errorMessage: message,
          });
          commit("resetData");
        }
      }
    },
  },
  namespaced: true,
};
