<template>
  <div class="area-row__item">
    <div class="area-row__label">Product</div>
    <a
      class="is-link print__no_underlined"
      :href="
        matchingProduct && matchingProduct.url ? matchingProduct.url : null
      "
      target="_blank"
      @click="(e) => $outbound(e)"
    >
      <img v-if="imageSrc" class="area-row__image" :src="imageSrc" />
    </a>
    <div class="area-row__text">
      <a
        v-if="matchingProduct"
        class="is-link print__no_underlined"
        :href="matchingProduct.url ? matchingProduct.url : null"
        target="_blank"
        @click="(e) => $outbound(e)"
        >{{ productName }}</a
      >
      <span v-if="!matchingProduct"> {{ productName }} </span>
    </div>
  </div>
</template>

<script>
import autocomplete from "@/data/web/index.js";

export default {
  props: {
    productName: {
      type: String,
      required: true,
    },
  },
  computed: {
    imageSrc() {
      return this.matchingProduct.img
        ? `/images/product/${this.matchingProduct.img}`
        : null;
    },
    matchingProduct() {
      return (
        autocomplete.products.find(
          (product) => product.name === this.productName
        ) || false
      );
    },
  },
};
</script>
<style lang="scss" scoped>
// Print styles
@media print {
  .print__no_underlined {
    @apply no-underline;
  }
}
</style>
