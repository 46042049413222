<template>
  <div>
    <div class="occ-bg--light-grey pt-8 md:pt-16">
      <div class="container mx-auto">
        <img
          v-if="$isRegion('nz')"
          src="@/assets/icons/dulux-nz-logo.png"
          class="pb-4 w-32 md:w-48"
        />
        <img
          v-else
          src="@/assets/icons/dulux-logo.svg"
          class="pb-4 w-32 md:w-48"
        />
      </div>
    </div>

    <ValidationObserver v-slot="{ invalid }" ref="observer">
      <form @submit.prevent="handleSubmit">
        <div class="occ-bg--light-grey">
          <Deal v-bind="deal" :deal-id="dealId" />
        </div>

        <div class="occ-bg--grey">
          <div class="container mx-auto pt-10 lg:py-12">
            <h2 v-if="deal.deal_name" class="pb-2 text-xl">
              {{ deal.deal_name }}
            </h2>

            <span v-if="deal.address">
              <p class="py-1">Project address: {{ deal.address }}</p>
            </span>

            <InputConsultationDate />
            <InputListProjectArea area-type="interior" area-name="Interior" />
            <InputListProjectArea
              area-type="exterior"
              area-name="Exterior"
              class-name="mt-10"
            />
            <InputComments />
            <InputFileUpload :deal-id="deal.deal_id" />
            <InputIntro />
            <div>
              <button
                class="w-full md:w-48 text-base is-button-solid my-4"
                @click.prevent="handlePreview"
              >
                Preview
              </button>
            </div>
            <div
              v-if="submitted && (invalid || invalidProjectAreaCount)"
              class="is-error"
            >
              Please ensure all fields marked * are complete
            </div>
            <div v-if="submitted && processingFiles" class="is-error">
              Upload in progress
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Deal from "@/components/form/Deal.vue";
import InputConsultationDate from "@/components/form/InputConsultationDate.vue";
import InputComments from "@/components/form/InputComments.vue";
import InputIntro from "@/components/form/InputIntro.vue";
import InputFileUpload from "@/components/form/InputFileUpload.vue";
import InputListProjectArea from "@/components/form/InputListProjectArea.vue";

export default {
  name: "Form",
  components: {
    ValidationObserver,
    Deal,
    InputConsultationDate,
    InputComments,
    InputIntro,
    InputFileUpload,
    InputListProjectArea,
  },

  props: {
    deal: {
      type: Object,
      required: true,
    },
    dealId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      submitted: false,
    };
  },
  computed: {
    processingFiles() {
      return this.$store.getters["form/processingFiles"];
    },

    invalidProjectAreaCount() {
      return this.totalProjectAreaCount < 1;
    },

    formData() {
      return this.$store.getters["form/formData"];
    },

    totalProjectAreaCount() {
      return parseInt(
        this.formData.interior.length + this.formData.exterior.length,
        10
      );
    },
  },
  methods: {
    handleSubmit() {
      return;
    },
    handlePreview() {
      this.submitted = true;
      if (this.processingFiles) {
        return;
      }
      if (this.invalidProjectAreaCount) {
        return;
      }
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        this.$store.commit("togglePreviewMode");
      });
    },
  },
};
</script>
