var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section bg-footer is-print-hidden",attrs:{"id":"occ-footer"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"occ_footer--top flex-col md:flex-row"},[_vm._m(0),_c('div',{staticClass:"flex items-center pt-2 md:pt-0"},[_c('a',{staticClass:"occ__social-hover",attrs:{"href":_vm.$isRegion('au')
              ? 'https://www.facebook.com/DuluxAustralia/'
              : 'https://www.facebook.com/DuluxNZ',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_c('facebook',{staticClass:"w-6"})],1),_c('a',{staticClass:"occ__social-hover",attrs:{"href":_vm.$isRegion('au')
              ? 'https://www.instagram.com/duluxaus/'
              : 'https://www.instagram.com/duluxnz',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_c('instagram',{staticClass:"w-6 mx-6"})],1),_c('a',{staticClass:"occ__social-hover",attrs:{"href":_vm.$isRegion('au')
              ? 'https://www.pinterest.com.au/duluxaustralia/'
              : 'https://www.pinterest.nz/duluxnz',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_c('pinterest',{staticClass:"w-6 mr-6"})],1),_c('a',{staticClass:"occ__social-hover",attrs:{"href":_vm.$isRegion('au')
              ? 'https://www.youtube.com/user/duluxaustralia'
              : 'https://www.youtube.com/user/duluxnz',"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_c('youtube',{staticClass:"w-6"})],1)])]),(_vm.enableSections)?_c('div',{staticClass:"occ_footer--nav nav--text hidden md:flex"},_vm._l((_vm.links.groups),function(group){return _c('div',{key:group.caption,staticClass:"w-1/6"},[_c('span',{staticClass:"text-dulux font-medium"},[_vm._v(_vm._s(group.caption))]),_c('ul',{staticClass:"mt-4"},_vm._l((group.items),function(link){return _c('li',{key:link.caption},[_c('a',{attrs:{"href":_vm.regionLink(link),"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._v(_vm._s(link.caption))])])}),0)])}),0):_vm._e(),(_vm.enableSections)?_c('div',{staticClass:"block md:hidden"},[_c('div',_vm._l((_vm.links.groups),function(group){return _c('accordion',{key:group.caption,attrs:{"title":group.caption}},[_c('ul',{staticClass:"occ__nav--mobile"},_vm._l((group.items),function(link){return _c('li',{key:link.caption},[_c('a',{attrs:{"href":_vm.regionLink(link),"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._v(_vm._s(link.caption))])])}),0)])}),1)]):_vm._e(),_c('div',{staticClass:"occ_footer--company flex-col lg:flex-row px-4 md:px-0 pt-6 lg:pt-12 pb-12 lg:pb-20"},[(_vm.links.contact && _vm.links.contact.length > 0)?_c('ul',{staticClass:"flex flex-col xl:flex-row justify-between text-sm w-full xl:w-2/5"},_vm._l((_vm.links.contact),function(link){return _c('li',{key:link.caption},[_c('a',{staticClass:"py-3 xl:py-0",attrs:{"href":_vm.regionLink(link),"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._v(_vm._s(link.caption))])])}),0):_vm._e(),_c('ul',{class:`occ_footer--legal
          justify-start
          md:justify-between
          w-full
          ${_vm.links.contact && _vm.links.contact.length > 0 ? 'xl:w-5/12' : ''}
          flex-wrap
          xl:flex-no-wrap
          border-t border-solid border-gray-600
          md:border-none
          pt-6
          md:pt-0
          mt-4
          lg:mt-0`},[_c('li',{class:`pr-6 pb-6 ${
            _vm.links.contact && _vm.links.contact.length > 0 ? 'md:pr-0 md:mb-0' : ''
          }`},[_vm._v(" © 2024 Dulux ")]),_vm._l((_vm.links.policy),function(link){return _c('li',{key:link.caption,class:`mb-6  pr-6 ${
            _vm.links.contact && _vm.links.contact.length > 0 ? 'md:pr-0 md:mb-0' : ''
          }`},[_c('a',{attrs:{"href":_vm.regionLink(link),"target":"_blank"},on:{"click":(e) => _vm.$outbound(e)}},[_vm._v(_vm._s(link.caption))])])})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"font-extrabold text-base md:text-2xl pl-6 text-dulux"},[_vm._v(" Worth doing, worth Dulux."),_c('sup',[_vm._v("®")])])])
}]

export { render, staticRenderFns }