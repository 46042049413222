<template>
  <div>
    <button
      v-if="!showNote"
      class="flex items-center mb-2"
      :disabled="isInputDisabled"
      @click.prevent="handleShowNote"
    >
      <IconPlus class="mr-2" width="12" height="12" /> Add note
    </button>
    <validation-provider v-show="showNote" v-slot="{ errors }" rules="">
      <label class="block lg:hidden my-2">{{ label }}</label>
      <TextareaAutosize
        ref="textarea"
        v-model="computedAreaValue"
        class="is-textarea"
        :placeholder="placeholder"
        :min-height="30"
        :max-height="350"
        :disabled="isInputDisabled"
      />
      <div class="is-error">{{ errors[0] }}</div>
    </validation-provider>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import IconPlus from "@/components/icons/Plus.vue";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  components: {
    ValidationProvider,
    IconPlus,
  },
  props: {
    areaType: {
      type: String,
      default: null,
    },
    areaKey: {
      type: String,
      required: true,
    },
    areaIndex: {
      type: Number,
      required: true,
    },
    areaValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
    isInputDisabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      showNote: false,
    };
  },

  computed: {
    computedAreaValue: {
      get() {
        return this.areaValue;
      },
      set: debounce(function (value) {
        this.$store.commit("form/updateProjectAreaItem", {
          areaType: this.areaType,
          areaKey: this.areaKey,
          areaIndex: this.areaIndex,
          areaValue: value,
        });
      }, 150),
    },
  },
  mounted() {
    this.showNote = !!this.computedAreaValue;
  },

  methods: {
    handleShowNote() {
      this.showNote = true;
      this.$nextTick(() => {
        this.$refs.textarea.$el.focus();
        this.$refs.textarea.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  @apply appearance-none;
  @apply border;
  @apply leading-tight;
  @apply mb-2;
  @apply px-3;
  @apply py-2;
  @apply rounded;
  @apply shadow;
  @apply text-gray-700;
  @apply w-full;
  &:focus {
    @apply outline-none;
    @apply shadow-outline;
  }
}

textarea:disabled {
  background-color: white !important;
}
</style>
