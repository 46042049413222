<template>
  <main id="app-report">
    <SectionHeader v-bind="$props" />
    <SectionGreeting
      :contact-firstname="contact_firstname"
      :intro="intro"
      :designer-name="designer_name"
    />
    <SectionProjectAreaTable
      v-if="interior && interior.length > 0"
      :areas="interior"
      title="Interior"
    />
    <SectionProjectAreaTable
      v-if="exterior && exterior.length > 0"
      :areas="exterior"
      title="Exterior"
    />
    <SectionFileUploads
      v-if="uploaded_files && uploaded_files.length > 0"
      :files="uploaded_files"
    />

    <SectionNotes v-if="!!comments" :comments="comments" />

    <SectionServicesAU v-if="$isRegion('au')" />
    <SectionServicesNZ v-if="$isRegion('nz')" />
    <SectionRecommended />
    <SectionDisclaimer />
    <SectionFooter />
  </main>
</template>

<script>
import SectionHeader from "@/components/report/SectionHeader";
import SectionProjectAreaTable from "@/components/report/SectionProjectAreaTable";
import SectionFileUploads from "@/components/report/SectionFileUploads";
import SectionNotes from "@/components/report/SectionNotes";
import SectionServicesAU from "@/components/report/SectionServicesAU";
import SectionServicesNZ from "@/components/report/SectionServicesNZ";
import SectionRecommended from "@/components/report/SectionRecommended";
import SectionDisclaimer from "@/components/report/SectionDisclaimer";
import SectionFooter from "@/components/report/SectionFooter";
import SectionGreeting from "@/components/report/SectionGreeting";

export default {
  name: "Report",
  components: {
    SectionHeader,
    SectionProjectAreaTable,
    SectionFileUploads,
    SectionNotes,
    SectionServicesAU,
    SectionServicesNZ,
    SectionRecommended,
    SectionDisclaimer,
    SectionGreeting,
    SectionFooter,
  },
  props: {
    deal_id: {
      type: String,
      default: null,
    },
    designer_name: {
      type: String,
      default: null,
    },
    designer_email: {
      type: String,
      default: null,
    },
    designer_phone: {
      type: String,
      default: null,
    },
    contact_firstname: {
      type: String,
      default: null,
    },
    contact_lastname: {
      type: String,
      default: null,
    },
    contact_fullname: {
      type: String,
      default: null,
    },
    contact_phone: {
      type: String,
      default: null,
    },
    contact_email: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
    consultation_date: {
      type: [Date, String],
      default: null,
    },
    comments: {
      type: String,
      default: null,
    },
    intro: {
      type: String,
      default: null,
    },
    exterior: {
      type: Array,
      default: () => [],
    },
    interior: {
      type: Array,
      default: () => [],
    },
    uploaded_files: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    contact_name() {
      return `${this.contact_firstname} ${this.contact_lastname}`;
    },
  },
};
</script>
