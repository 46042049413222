<template>
  <div id="occ-footer" class="section bg-footer is-print-hidden">
    <div class="container">
      <div class="occ_footer--top flex-col md:flex-row">
        <div class="flex items-center">
          <div class="font-extrabold text-base md:text-2xl pl-6 text-dulux">
            Worth doing, worth Dulux.<sup>&reg;</sup>
          </div>
        </div>

        <div class="flex items-center pt-2 md:pt-0">
          <a
            :href="
              $isRegion('au')
                ? 'https://www.facebook.com/DuluxAustralia/'
                : 'https://www.facebook.com/DuluxNZ'
            "
            target="_blank"
            class="occ__social-hover"
            @click="(e) => $outbound(e)"
          >
            <facebook class="w-6" />
          </a>
          <a
            :href="
              $isRegion('au')
                ? 'https://www.instagram.com/duluxaus/'
                : 'https://www.instagram.com/duluxnz'
            "
            target="_blank"
            class="occ__social-hover"
            @click="(e) => $outbound(e)"
          >
            <instagram class="w-6 mx-6" />
          </a>
          <a
            :href="
              $isRegion('au')
                ? 'https://www.pinterest.com.au/duluxaustralia/'
                : 'https://www.pinterest.nz/duluxnz'
            "
            target="_blank"
            class="occ__social-hover"
            @click="(e) => $outbound(e)"
          >
            <pinterest class="w-6 mr-6" />
          </a>
          <a
            :href="
              $isRegion('au')
                ? 'https://www.youtube.com/user/duluxaustralia'
                : 'https://www.youtube.com/user/duluxnz'
            "
            target="_blank"
            class="occ__social-hover"
            @click="(e) => $outbound(e)"
          >
            <youtube class="w-6" />
          </a>
        </div>
      </div>

      <div
        v-if="enableSections"
        class="occ_footer--nav nav--text hidden md:flex"
      >
        <div v-for="group in links.groups" :key="group.caption" class="w-1/6">
          <span class="text-dulux font-medium">{{ group.caption }}</span>
          <ul class="mt-4">
            <li v-for="link in group.items" :key="link.caption">
              <a
                :href="regionLink(link)"
                target="_blank"
                @click="(e) => $outbound(e)"
                >{{ link.caption }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div v-if="enableSections" class="block md:hidden">
        <div>
          <accordion
            v-for="group in links.groups"
            :key="group.caption"
            :title="group.caption"
          >
            <ul class="occ__nav--mobile">
              <li v-for="link in group.items" :key="link.caption">
                <a
                  :href="regionLink(link)"
                  target="_blank"
                  @click="(e) => $outbound(e)"
                  >{{ link.caption }}</a
                >
              </li>
            </ul>
          </accordion>
        </div>
      </div>

      <div
        class="
          occ_footer--company
          flex-col
          lg:flex-row
          px-4
          md:px-0
          pt-6
          lg:pt-12
          pb-12
          lg:pb-20
        "
      >
        <ul
          v-if="links.contact && links.contact.length > 0"
          class="
            flex flex-col
            xl:flex-row
            justify-between
            text-sm
            w-full
            xl:w-2/5
          "
        >
          <li v-for="link in links.contact" :key="link.caption">
            <a
              class="py-3 xl:py-0"
              :href="regionLink(link)"
              target="_blank"
              @click="(e) => $outbound(e)"
              >{{ link.caption }}</a
            >
          </li>
        </ul>

        <ul
          :class="`occ_footer--legal
            justify-start
            md:justify-between
            w-full
            ${links.contact && links.contact.length > 0 ? 'xl:w-5/12' : ''}
            flex-wrap
            xl:flex-no-wrap
            border-t border-solid border-gray-600
            md:border-none
            pt-6
            md:pt-0
            mt-4
            lg:mt-0`"
        >
          <li
            :class="`pr-6 pb-6 ${
              links.contact && links.contact.length > 0 ? 'md:pr-0 md:mb-0' : ''
            }`"
          >
            © 2024 Dulux
          </li>
          <li
            v-for="link in links.policy"
            :key="link.caption"
            :class="`mb-6  pr-6 ${
              links.contact && links.contact.length > 0 ? 'md:pr-0 md:mb-0' : ''
            }`"
          >
            <a
              :href="regionLink(link)"
              target="_blank"
              @click="(e) => $outbound(e)"
              >{{ link.caption }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import isRegion from "@/lib/region";
import { linkAtBase } from "@/lib/links";
import accordion from "../accordion/Accordion";
import facebook from "../icons/Facebook";
import instagram from "../icons/Instagram";
import pinterest from "../icons/Pinterest";
import youtube from "../icons/Youtube";

export default {
  components: {
    accordion,
    facebook,
    instagram,
    pinterest,
    youtube,
  },
  data() {
    const baseLinks = {
      groups: [
        {
          caption: "Colours",
          items: [
            {
              caption: "Whites & Neutrals",
              link: "https://www.dulux.com.au/colour/whites-and-neutrals/popular-whites",
              linkNz:
                "https://www.dulux.co.nz/colour/whites-and-neutrals/popular-whites",
            },
            {
              caption: "Greys",
              link: "https://www.dulux.com.au/colour/greys/popular",
              linkNz: "https://www.dulux.co.nz/colour/greys/popular",
            },
            {
              caption: "Browns",
              link: "https://www.dulux.com.au/colour/browns/popular",
              linkNz: "https://www.dulux.co.nz/colour/browns/popular",
            },
            {
              caption: "Purples & Pinks",
              link: "https://www.dulux.com.au/colour/purples/popular",
              linkNz: "https://www.dulux.co.nz/colour/purples/popular",
            },
            {
              caption: "Blues",
              link: "https://www.dulux.com.au/colour/blues/popular",
              linkNz: "https://www.dulux.co.nz/colour/blues/popular",
            },
            {
              caption: "Colour Wall",
              link: "https://www.dulux.com.au/colour/all-colours",
              linkNz: "https://www.dulux.co.nz/colour/all-colours",
            },
            {
              caption: "Design Effects Range",
              link: "https://www.dulux.com.au/colour/design-range/all-swatches",
              linkNz:
                "https://www.dulux.co.nz/colour/design-range/all-swatches",
            },
            {
              caption: "Colour Forecast",
              link: "https://www.dulux.com.au/colour/colour-trends/2024",
              linkNz: "https://www.dulux.co.nz/colour/colour-forecast/2024",
            },
          ],
        },
        {
          caption: "Products",
          items: [
            {
              caption: "Interior",
              link: "https://www.dulux.com.au/products/interior",
              linkNz: "https://www.dulux.co.nz/products/interior",
            },
            {
              caption: "Exterior",
              link: "https://www.dulux.com.au/products/exterior",
              linkNz: "https://www.dulux.co.nz/products/exterior",
            },
            {
              caption: "Enamels",
              link: "https://www.dulux.com.au/products/enamels",
              linkNz: "https://www.dulux.co.nz/products/enamels",
            },
            {
              caption: "Effects",
              link: "https://www.dulux.com.au/products/interior/effects",
              linkNz: "https://www.dulux.co.nz/products/interior/effects",
            },
            {
              caption: "Accessories",
              link: "https://www.dulux.com.au/products/accessories",
              linkNz: "https://www.dulux.co.nz/products", // is there an accessories section for NZ?
            },
            {
              caption: "Sprays",
              link: "https://www.dulux.com.au/products/interior/spray-paint",
              linkNz: "https://www.dulux.co.nz/products/interior/spray-paint",
            },
            {
              caption: "Safety Data Sheets",
              link: "https://www.dulux.com.au/products/sds",
              linkNz: "https://www.dulux.co.nz/products/sds",
            },
          ],
        },
        {
          caption: "Our brands",
          items: [
            {
              caption: "Renovation Range",
              link: "https://www.dulux.com.au/products/renovation-range/overview",
            },
            {
              caption: "Wash&Wear",
              link: "https://www.dulux.com.au/products/washandwear",
            },
            {
              caption: "Weathershield",
              link: "https://www.dulux.com.au/products/weathershield/overview",
            },
            {
              caption: "Duramax",
              link: "https://www.dulux.com.au/products/duramax/overview",
            },
            {
              caption: "Metalshield",
              link: "https://www.dulux.com.au/products/metalshield",
            },
            {
              caption: "RapidFinish",
              link: "https://www.dulux.com.au/products/rapidfinish",
            },
            {
              caption: "Render Refresh",
              link: "https://www.dulux.com.au/products/renderrefresh",
            },
            {
              caption: "Precision",
              link: "https://www.dulux.com.au/products/precision/overview",
            },
            {
              caption: "Design Effects",
              link: "https://www.dulux.com.au/products/designeffects/overview",
            },
          ],
        },
        {
          caption: "Inspiration",
          items: [
            {
              caption: "United by style",
              link: "https://www.dulux.com.au/inspiration/united-by-style",
            },
            {
              caption: "The Grace Tales",
              link: "https://www.dulux.com.au/inspiration/the-grace-tales",
            },
            {
              caption: "Norsu Interiors",
              link: "https://www.dulux.com.au/inspiration/norsu-interiors",
            },
            {
              caption: "Inspiration gallery",
              link: "https://www.dulux.com.au/inspiration/inspirationgallery",
            },
            {
              caption: "The Design Files",
              link: "https://www.dulux.com.au/inspiration/the-design-files/overview",
            },
            {
              caption: "Three Birds Renovations",
              link: "https://www.dulux.com.au/inspiration/three-birds-renovations/the-rivershack",
            },
          ],
        },
        {
          caption: "Services",
          items: [
            {
              caption: "Dulux Colour Designers",
              link: "https://www.dulux.com.au/services/colour-design-service/overview",
              linkNz:
                "https://www.dulux.co.nz/services/colour-design-service/overview",
            },
            {
              caption: "Find a Painter",
              link: "https://www.dulux.com.au/services/find-a-painter",
              linkNz: "https://www.dulux.co.nz/services/find-a-painter-nz",
            },
            {
              caption: "Accredited Painters",
              link: "https://www.dulux.com.au/services/dulux-accredited-painters/overview",
              linkNz: "https://www.dulux.co.nz/services/find-a-painter-nz", // accredited painters not present in NZ
            },
            {
              caption: "Find a Store",
              link: "https://www.dulux.com.au/find-a-store",
              linkNz: "https://www.dulux.co.nz/find-a-store",
            },
          ],
        },
        {
          caption: "How to",
          items: [
            {
              caption: "Project guides",
              link: "https://www.dulux.com.au/how-to/project-guides",
            },
            {
              caption: "Tips & Techniques",
              link: "https://www.dulux.com.au/how-to/general",
            },
            {
              caption: "Preparation & painting guides",
              link: "https://www.dulux.com.au/how-to/preparation-and-painting-guides",
            },
            {
              caption: "Paint problem solver",
              link: "https://www.dulux.com.au/how-to/general/paint-problem-solver",
            },
            {
              caption: "How to use colour",
              link: "https://www.dulux.com.au/how-to/how-to-use-colour",
            },
          ],
        },
      ],

      contact: [
        // removed for Oct 2021 release
      ],

      policy: [
        {
          caption: "Contact Us",
          link: linkAtBase("/footer/contact-us"),
        },
        {
          caption: "Shipping & Returns",
          link: linkAtBase("/shipping-and-returns"),
        },
        {
          caption: "Careers",
          link: linkAtBase("/footer/careers/dulux-careers-faq"),
        },
        {
          caption: "Privacy Policy",
          link: linkAtBase("/privacy-policy"),
        },
        {
          caption: "Corporate Info",
          link: linkAtBase("/footer/corporate-info"),
        },
        {
          caption: "Recommended sites",
          link: linkAtBase("/footer/recommended-sites"),
        },
        {
          caption: "News & Media",
          link: linkAtBase("/footer/media/media-overview"),
        },
        {
          caption: "Site terms",
          link: linkAtBase("/footer/site-terms"),
        },
        {
          caption: "COVID-19 Update",
          link: linkAtBase("/footer/covid-19-update/consumer"),
        },
      ],
    };

    if (isRegion("nz")) {
      const nzBrands = [
        {
          caption: "Renovation Range",
          linkNz: "https://www.dulux.co.nz/products/renovation-range/overview",
        },
        {
          caption: "Wash&Wear",
          linkNz: "https://www.dulux.co.nz/products/washandwear",
        },
        {
          caption: "Weathershield",
          linkNz: "https://www.dulux.co.nz/products/weathershield/overview",
        },
        {
          caption: "Duramax",
          linkNz: "https://www.dulux.co.nz/products/duramax/overview",
        },
        {
          caption: "Metalshield",
          linkNz: "https://www.dulux.co.nz/products/metalshield",
        },
        {
          caption: "Render Refresh",
          linkNz: "https://www.dulux.co.nz/products/renderrefresh",
        },
        {
          caption: "Precision",
          linkNz: "https://www.dulux.co.nz/products/precision/overview",
        },
        {
          caption: "Design Effects",
          linkNz: "https://www.dulux.co.nz/products/designeffects/overview",
        },
        {
          caption: "Roof & Trim",
          linkNz: "https://www.dulux.co.nz/products/roof-and-trim",
        },
        {
          caption: "Timbacryl",
          linkNz: "https://www.dulux.co.nz/products/timbacryl",
        },
        {
          caption: "envirO2",
          linkNz: "https://www.dulux.co.nz/products/envirO2-range/overview",
        },
      ];

      const nzInspiration = [
        {
          caption: "Inspiration gallery",
          linkNz: "https://www.dulux.co.nz/inspiration/inspirationgallery",
        },
        {
          caption: "Alex & Corban",
          linkNz: "https://www.dulux.co.nz/inspiration/alex-corban",
        },
        {
          caption: "Michelle Hallford",
          linkNz: "https://www.dulux.co.nz/inspiration/michelle-halford",
        },
        {
          caption: "Evie Kemp",
          linkNz: "https://www.dulux.co.nz/inspiration/evie-kemp",
        },
      ];

      const nzHowTo = [
        {
          caption: "Preparation & painting guides",
          linkNz:
            "https://www.dulux.co.nz/how-to/preparation-and-painting-guides",
        },
        {
          caption: "Project guides",
          linkNz: "https://www.dulux.co.nz/how-to/project-guides",
        },
        {
          caption: "How to use colour",
          linkNz: "https://www.dulux.co.nz/how-to/how-to-use-colour",
        },
        {
          caption: "Outdoor projects",
          linkNz: "https://www.dulux.co.nz/how-to/outdoor-projects/Overview",
        },
        {
          caption: "Interior projects",
          linkNz: "https://www.dulux.co.nz/how-to/take-on-interior-projects",
        },
        {
          caption: "Tips & Techniques",
          linkNz: "https://www.dulux.co.nz/how-to/general",
        },
      ];

      const contactNz = [
        {
          caption: "Contact us",
          linkNz: "https://www.dulux.co.nz/footer/contact-us",
        },
        {
          caption: "News & Media",
          linkNz: "https://www.dulux.co.nz/footer/media/media-overview",
        },
        {
          caption: "Careers",
          linkNz:
            "https://www.dulux.co.nz/footer/careers/dulux-careers-faq.html",
        },
        {
          caption: "Colour accuracy",
          linkNz: "https://www.dulux.co.nz/colour/about-colour/colour-accuracy",
        },
        {
          caption: "COVID-19 Update",
          linkNz: "https://www.dulux.co.nz/footer/covid-19-update",
        },
      ];

      const policyNz = [
        {
          caption: "Privacy policy",
          linkNz: "https://www.dulux.co.nz/footer/privacy-policy",
        },
        {
          caption: "Corporate info",
          linkNz: "https://www.dulux.co.nz/footer/corporate-info",
        },
        {
          caption: "Recommended sites",
          linkNz: "https://www.dulux.co.nz/footer/recommended-sites",
        },
        {
          caption: "Site terms",
          linkNz: "https://www.dulux.co.nz/footer/site-terms",
        },
        {
          caption: "Site map",
          linkNz: "https://www.dulux.co.nz/footer/site-map",
        },
      ];

      const patchItems = (groupName, newItems) =>
        (baseLinks.groups.find((g) => g.caption === groupName).items =
          newItems);

      patchItems("Our brands", nzBrands);
      patchItems("Inspiration", nzInspiration);
      patchItems("How to", nzHowTo);

      baseLinks.contact = contactNz;
      baseLinks.policy = policyNz;
    }

    return { links: baseLinks };
  },
  computed: {
    enableSections() {
      // disabled for AU: for the initial new website launch for 19 Oct 2021
      if (isRegion("au")) return false;

      // still enabled for NZ
      return true;
    },
  },
  methods: {
    regionLink(link) {
      return isRegion("nz") && link.linkNz ? link.linkNz : link.link;
    },
    linkAtBase,
    isRegion,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins.scss";

.bg-footer {
  @apply bg-navy-2;
}

.occ_footer {
  &--top {
    @apply flex items-center justify-between py-4;
  }

  &--nav {
    @apply py-12 text-sm;
    border-top: 1px solid #e1ecf1;
    border-bottom: 1px solid #e1ecf1;
  }

  &--nav {
    li {
      @apply mb-4;

      a:hover {
        text-decoration: underline;
      }
    }
  }

  &--company {
    @apply flex justify-between;
    @apply text-gray-100;

    a {
      @apply block;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--legal {
    @apply flex flex-row text-xs;
    @apply text-gray-200;
  }
}

.occ__nav--mobile li a {
  @apply py-3 block;
}

.occ__social-hover {
  @apply text-gray-100;
}

.text-dulux {
  @apply text-gray-100;
}

.bg-blue {
  @apply bg-navy-2;
}

.nav--text {
  @apply text-gray-100;
}
</style>
