import api from "@/api";

export default {
  state: {
    network: {
      loading: false,
      error: false,
      errorMessage: null,
    },
    data: null,
    reportId: null,
  },

  getters: {
    network(state) {
      return state.network;
    },

    data(state) {
      return state.data;
    },
  },

  mutations: {
    setNetwork(state, payload) {
      state.network = payload;
    },

    setData(state, data) {
      state.data = data;
    },

    resetData(state) {
      state.data = null;
    },
    setReportId(state, payload) {
      state.reportId = payload;
    },
  },

  actions: {
    async getById({ commit }, { id }) {
      if (id) {
        try {
          commit("setNetwork", {
            loading: true,
            error: false,
            errorMessage: null,
          });
          const { data } = await api.get(`/reports/${id}`);
          // Check for and handle synthetic status codes
          if (data.status && data.status !== 200) {
            throw new Error(`${data.status} / ${data.message}`);
          }
          commit("setNetwork", {
            loading: false,
            error: false,
            errorMessage: null,
          });
          commit("setData", data);
        } catch ({ message }) {
          commit("setNetwork", {
            loading: false,
            error: true,
            errorMessage: message,
          });
          commit("resetData");
        }
      }
    },
  },
  namespaced: true,
};
