<template>
  <div>
    <div v-if="message">
      <h1>{{ message }}</h1>
    </div>
    <div v-if="!network.loading && !network.error && reportData">
      <Report v-bind="reportData" />
    </div>
  </div>
</template>

<script>
import Report from "@/components/report/Report";
export default {
  name: "ReportView",
  components: {
    Report,
  },
  props: {
    reportId: {
      type: String,
      required: true,
    },
  },
  computed: {
    report() {
      return this.$store.state.report;
    },
    message() {
      return this.network.loading
        ? "Loading..."
        : this.network.error
        ? this.network.errorMessage || "Error"
        : null;
    },
    network() {
      return this.$store.getters["report/network"];
    },
    reportData() {
      return this.$store.getters["report/data"];
    },
  },
  mounted() {
    if (this.reportId) {
      this.$store.commit("report/setReportId", this.reportId);
      this.getReport();
    }
  },
  methods: {
    getReport() {
      this.$store.dispatch("report/getById", {
        id: this.reportId,
      });
    },
  },
};
</script>
