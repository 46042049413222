import router from "@/router/index.js";
import api from "@/api";
import EventBus from "@/eventbus";

export default {
  state: {
    network: {
      loading: false,
      error: false,
      errorMessage: null,
      processingFiles: false,
    },
    reportNetwork: {
      submitting: false,
      error: false,
      errors: null,
      unstableNetwork: false,
      unstableNetworkTimeout: null,
    },
    data: {
      editing: false,
      consultation_date: null,
      intro: null,
      comments: null,
      exterior: [],
      interior: [],
      uploaded_files: [],
    },
    serverUploadedFiles: [],
    reportId: null,
  },

  getters: {
    network(state) {
      return state.network;
    },

    reportNetwork(state) {
      return state.reportNetwork;
    },

    unstableNetwork(state) {
      return state.reportNetwork.unstableNetwork;
    },

    formData(state) {
      return state.data;
    },

    processingFiles(state) {
      return !!state.network.processingFiles;
    },

    requestPayload(state) {
      const fileUuids = state.data.uploaded_files.map((file) => file.uuid);
      return { ...state.data, uploaded_file_uuids: fileUuids };
    },

    serverUploadedFiles(state) {
      return state.data.uploaded_files;
    },
  },

  mutations: {
    setNetwork(state, payload) {
      state.network = { ...state.network, ...payload };
    },
    clearNetwork(state) {
      state.network = {
        ...state.network,
        loading: false,
        error: false,
        errorMessage: null,
        errors: null,
      };
    },

    setReportNetwork(state, payload) {
      state.reportNetwork = { ...state.reportNetwork, ...payload };
    },
    setReportSubmitting(state) {
      state.reportNetwork = {
        ...state.reportNetwork,
        submitting: true,
        error: false,
        errors: null,
      };
    },
    stopReportSubmitting(state) {
      state.reportNetwork = {
        ...state.reportNetwork,
        submitting: false,
      };
    },
    setReportTimeout(state) {
      console.debug("setReportTimeout");
      if (state.reportNetwork.unstableNetworkTimeout) {
        console.debug("> timeout already set, skipping");
        return;
      }

      const timeout = setTimeout(() => {
        console.debug("timeout expired setReportTimeout");
        state.reportNetwork = { ...state.reportNetwork, unstableNetwork: true };
      }, 10000);

      state.reportNetwork = {
        ...state.reportNetwork,
        submitting: true,
        unstableNetworkTimeout: timeout,
      };
    },
    setUnstableNetwork(state, value = true) {
      state.reportNetwork = { ...state.reportNetwork, unstableNetwork: value };
    },
    clearReportTimeout(state) {
      console.debug("clearReportTimeout called");
      if (state.reportNetwork.unstableNetworkTimeout) {
        console.debug(
          "clearing timeout",
          state.reportNetwork.unstableNetworkTimeout
        );
        clearTimeout(state.reportNetwork.unstableNetworkTimeout);
      } else {
        console.debug("> timeout not tracked in unstableNetworkTimeout");
      }
      state.reportNetwork = {
        ...state.reportNetwork,
        unstableNetwork: false,
        unstableNetworkTimeout: null,
      };
    },
    clearReportNetwork(state) {
      state.reportNetwork = {
        ...state.reportNetwork,
        submitting: false,
        error: false,
        errors: null,
        unstableNetwork: false,
        unstableNetworkTimeout: null,
      };
    },

    setNetworkProcessingFiles(state, payload) {
      state.network.processingFiles = payload;
    },

    setReportId(state, payload) {
      state.reportId = payload;
    },

    setConsultationDate(state, value) {
      state.data.consultation_date = value;
    },

    setIntro(state, value) {
      state.data.intro = value;
    },

    setComments(state, value) {
      state.data.comments = value;
    },

    setFormData(state, payload) {
      state.data = {
        editing: true,
        consultation_date: new Date(payload.consultation_date),
        intro: payload.intro,
        comments: payload.comments,
        exterior: payload.exterior || [],
        interior: payload.interior || [],
        uploaded_files: payload.uploaded_files || [],
      };
    },

    setServerUploadedFiles(state, payload) {
      state.serverUploadedFiles = payload;
    },

    addProjectAreaRow(state, { areaType }) {
      state.data[areaType] &&
        state.data[areaType].push({
          id: "_" + Math.random().toString(36).substr(2, 9),
          area: "",
          colour: "",
          product: "",
          note: "",
        });
    },

    removeProjectAreaRow(state, { areaType, id }) {
      // state.data[areaType].splice(areaIndex, 1);
      state.data[areaType] = state.data[areaType].filter(
        (item) => item.id !== id
      );
    },

    updateProjectAreaItem(state, { areaType, areaKey, areaIndex, areaValue }) {
      if (state.data[areaType] && state.data[areaType][areaIndex]) {
        state.data[areaType][areaIndex][areaKey] = areaValue;
      }
    },

    addUploadedFile(state, file) {
      state.data.uploaded_files.push(file);
    },

    removeUploadedFileByUuid(state, uuid) {
      state.data.uploaded_files = state.data.uploaded_files.filter(
        (file) => file.uuid !== uuid
      );
    },
  },

  actions: {
    async createReport({ commit, getters, rootGetters }) {
      try {
        if (getters.reportNetwork.submitting) {
          console.debug("already submitting a report");
          commit("setReportTimeout");
          return;
        }

        commit("setReportId", null);
        commit("setReportTimeout");
        const { data } = await api.post("/reports/store", {
          ...getters.requestPayload,
          ...rootGetters["deal/formPayload"],
        });
        commit("clearReportTimeout");
        if (data.status && data.status !== 200) {
          throw new Error(`${data.status} / ${data.message}`);
        }
        commit("clearReportNetwork");
        if (data.report_id) {
          router.push(`/report/${data.report_id}`);
        }
      } catch ({ response, message }) {
        commit("setReportId", null);
        commit("setReportNetwork", {
          submitting: false,
          error: true,
          errorMessage: message,
          errors: response?.data?.errors,
        });
      }
    },

    async updateReport({ commit, getters, rootGetters }, payload) {
      try {
        if (getters.reportNetwork.submitting) {
          console.debug("already submitting a report");
          // ensure we have a timeout running
          // the user can dismiss the foreground UI, while submission continues in the background
          // a new timeout might be required to reset the UI if this is a retry of a pending sumbit
          commit("setReportTimeout");
          return;
        }

        commit("setReportId", null);
        commit("setReportTimeout");
        const { data } = await api.put("/reports/update", {
          ...getters.requestPayload,
          ...rootGetters["deal/formPayload"],
          report_id: payload.edit_id,
          editing: true,
          // edit_id: payload.edit_id,
          // deal_id: payload.deal_id,
          // contact_id: payload.contact_id,
        });
        commit("clearReportTimeout");
        if (data.status && data.status !== 200) {
          throw new Error(`${data.status} / ${data.message}`);
        }
        commit("clearReportNetwork");
        if (data.report_id) {
          router.push(`/report/${data.report_id}`);
        }
      } catch ({ response, message }) {
        commit("setReportId", null);
        commit("setReportNetwork", {
          submitting: false,
          error: true,
          errorMessage: message,
          errors: response?.data?.errors,
        });
      }
    },

    async getFormFieldsByEditId({ commit }, { editId, dealId, contactId }) {
      try {
        commit("setNetwork", {
          loading: true,
          error: false,
          errorMessage: null,
        });
        const { data } = await api.get(`/reports/${editId}`, {
          params: {
            deal_id: dealId,
            contact_id: contactId,
          },
        });
        // Check for and handle synthetic status codes
        if (data.status && data.status !== 200) {
          throw new Error(`${data.status} / ${data.message}`);
        }

        commit("setFormData", data);

        if (data.uploaded_files && data.uploaded_files.length) {
          console.log("data.uploaded_files");
          // commit("setServerUploadedFiles", data.uploaded_files);
          setTimeout(() => {
            EventBus.$emit("uploaded_files_response", data.uploaded_files);
          }, 1000);
        }

        commit("clearNetwork");
      } catch ({ message }) {
        commit("setNetwork", {
          loading: false,
          error: true,
          errorMessage: message,
        });
      }
    },
  },

  namespaced: true,
};
