<template>
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen p-4 text-center">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-700 opacity-75"></div>
      </div>

      <div
        class="
          inline-block
          p-3
          overflow-hidden
          text-left
          align-bottom
          transition-all
          transform
          bg-white
          rounded-lg
          shadow-xl
        "
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="max-w-xs px-4 pt-5 pb-4 bg-white">
          <div class="">
            <div class="flex items-center justify-center">
              <svg
                width="126"
                height="70"
                viewBox="0 0 126 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M103.179 45.7011C103.179 50.9892 80.2967 55.276 52.07 55.276C23.8432 55.276 0.960938 50.9892 0.960938 45.7011C0.960938 42.1577 5.93853 39.5288 21.2105 37.873C21.2105 37.873 23.7983 46.4128 25.1569 47.3832C26.5155 48.3536 44.8871 47.9655 51.1642 47.9655C55.7991 47.9655 77.1543 50.3592 80.9886 46.2834L83.5764 39.1023C100.959 40.6307 103.179 41.8658 103.179 45.7011Z"
                  fill="#DADFE9"
                />
                <path
                  d="M78.8256 1.19067C80.5172 1.57416 83.9004 6.82793 83.9004 24.7751C83.9004 42.7223 80.5172 47.8908 78.8256 48.2317M78.8256 1.19067L57.1774 1.19067M78.8256 1.19067C77.4552 1.23328 74.7144 5.9459 74.7144 24.7112C74.7144 43.4764 77.4552 48.2104 78.8256 48.2317M78.8256 48.2317L26.1504 48.2317C22.8865 48.2317 20.2405 37.7012 20.2405 24.7112C20.2405 11.7212 22.8865 1.19067 26.1504 1.19067L57.1774 1.19067M78.8256 48.2317C66.4919 55.6458 49.6615 51.2996 43.7516 40.1785C37.8418 29.0574 40.2186 7.90169 57.1774 1.19067"
                  stroke="#041A37"
                  stroke-width="1.70951"
                />
                <path
                  d="M98.7794 36.9024C88.4604 37.1935 81.8078 37.5924 76.2009 37.6787C76.2009 37.6787 76.6538 46.0891 80.0179 48.6769C78.4006 49.3238 75.1011 51.1353 75.1011 51.1353C76.395 51.3078 74.7129 50.4289 74.7129 58.1223C74.7129 65.6269 84.302 69.4918 93.2158 68.9911C104.732 68.3441 123.22 65.5567 124.916 51.1353C126.613 36.7138 109.098 36.6112 98.7794 36.9024Z"
                  fill="#E21B24"
                />
              </svg>
            </div>
            <div class="mt-3 text-center">
              <div class="mt-2">
                <p>
                  It is taking longer than expected to submit this form. We
                  suggest trying again when your internet connection is more
                  stable.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="max-w-xs px-4 py-3 bg-gray-50">
          <button
            type="button"
            class="
              inline-flex
              justify-center
              w-full
              px-4
              py-3
              text-base
              font-medium
              text-white
              border border-transparent
              rounded-md
              shadow-sm
              bg-navy-1
              disabled:opacity-50
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-red-500
            "
            :class="{ 'disabled-button': isSubmitting }"
            :disabled="isSubmitting"
            @click.prevent="handleForceSubmit"
          >
            No thanks, I'll wait
            <svg
              v-if="isSubmitting"
              class="w-5 h-5 ml-3 text-white occ-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </button>

          <button
            type="button"
            class="
              inline-flex
              justify-center
              w-full
              px-4
              py-2
              mt-3
              text-base
              font-medium
              text-gray-700
              underline
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
            "
            @click.prevent="handleCloseModal"
          >
            Return to report preview
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    isEditing() {
      return this.$store.state.form.data.editing || false;
    },
  },
  methods: {
    handleCloseModal() {
      this.$store.commit("form/clearReportTimeout", false);
      this.$store.commit("form/setUnstableNetwork", false);
      // also ensure the other modal is not active (both states can be set)
      this.$store.commit("setErrorNetworkModalDisplayed", false);
      this.isSubmitting = false;
    },
    handleForceSubmit() {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      if (this.isEditing) {
        this.$store.dispatch("form/updateReport", {
          deal_id: this.$route.query.deal_id || null,
          contact_id: this.$route.query.contact_id || null,
          edit_id: this.$route.query.edit_id || null,
        });
      } else {
        this.$store.dispatch("form/createReport");
      }
    },
  },
};
</script>
