import Vue from "vue";
import Vuex from "vuex";

import deal from "./deal.js";
import form from "./form.js";
import report from "./report.js";

Vue.use(Vuex);

Vue.config.devtools = true;

export default new Vuex.Store({
  state: {
    previewMode: false,
    errorNetworkModalDisplayed: false,
    bypassPreflightCheck: false,
  },
  mutations: {
    togglePreviewMode(state) {
      state.previewMode = !state.previewMode;
    },

    toggleErrorNetworkModalDisplayed(state) {
      state.errorNetworkModalDisplayed = !state.errorNetworkModalDisplayed;
    },

    setErrorNetworkModalDisplayed(state, payload) {
      state.errorNetworkModalDisplayed = payload;
    },

    setBypassPreflightCheck(state, payload) {
      state.bypassPreflightCheck = payload;
    },
  },
  modules: {
    deal,
    form,
    report,
  },
});
