import Vue from "vue";
import VueRouter from "vue-router";

import FormPage from "@/pages/FormPage.vue";
import ReportView from "@/pages/ReportView.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "FormPage",
      component: FormPage,
      props: ({ query }) => ({
        dealId: query.deal_id,
        contactId: query.contact_id,
        editId: query.edit_id,
        preview: !!query.preview,
      }),
    },
    {
      path: "/report/:id",
      name: "ReportView",
      component: ReportView,
      props: ({ params }) => ({ reportId: params.id || null }),
    },
  ],
});

export default router;
