<template>
  <div id="occ-services" class="section bg-services is-print-hidden">
    <div class="container">
      <div class="flex flex-wrap">
        <h2 class="text-2xl md:text-4xl w-full lg:w-1/4 pt-8 lg:py-16">
          Services
        </h2>

        <div
          class="
            py-6
            lg:py-16
            w-full
            lg:w-3/4
            sm:grid sm:grid-rows-2 sm:grid-cols-2
            lg:grid-cols-3
            sm:grid-flow-row
            gap-4
            md:gap-6
            lg:gap-8
          "
        >
          <a
            :href="linkAtBase('/paint-calculator')"
            target="_blank"
            class="
              block
              bg-white
              p-4
              lg:py-6
              rounded-md
              mb-4
              sm:mb-0
              md:mb-2
              lg:mb-0
            "
            @click="(e) => $outbound(e)"
          >
            <div class="flex flex-no-wrap md:flex-wrap items-center">
              <div class="w-1/5 md:w-full">
                <img
                  src="@/assets/icons/paint-bucket.svg"
                  width="32"
                  class="inline-block"
                />
              </div>
              <p class="md:pt-5 text-lg w-4/5">Paint Calculator</p>
            </div>
          </a>
          <a
            :href="linkAtBase('/store-locator')"
            target="_blank"
            class="
              block
              bg-white
              p-4
              lg:py-6
              rounded-md
              mb-4
              sm:mb-0
              md:mb-2
              lg:mb-0
            "
            @click="(e) => $outbound(e)"
          >
            <div class="flex flex-no-wrap md:flex-wrap items-center">
              <div class="w-1/5 md:w-full">
                <img
                  src="@/assets/icons/location.svg"
                  width="24"
                  class="inline-block"
                />
              </div>
              <p class="md:pt-5 text-lg w-4/5">Find your nearest store</p>
            </div>
          </a>
          <a
            href="https://www.dulux.com.au/services/find-a-painter"
            target="_blank"
            class="
              block
              bg-white
              p-4
              lg:py-6
              rounded-md
              mb-4
              sm:mb-0
              md:mb-2
              lg:mb-0
            "
            @click="(e) => $outbound(e)"
          >
            <div class="flex flex-no-wrap md:flex-wrap items-center">
              <div class="w-1/5 md:w-full">
                <img
                  src="@/assets/icons/brush.svg"
                  width="28"
                  class="inline-block"
                />
              </div>
              <p class="md:pt-1 text-lg w-4/5">Find a Dulux Painter</p>
            </div>
          </a>
          <a
            :href="linkAtBase('/support/help-and-advice')"
            target="_blank"
            class="
              block
              bg-white
              p-4
              lg:py-6
              rounded-md
              mb-4
              sm:mb-0
              md:mb-2
              lg:mb-0
            "
            @click="(e) => $outbound(e)"
          >
            <div class="flex flex-no-wrap md:flex-wrap items-center">
              <div class="w-1/5 md:w-full">
                <img
                  src="@/assets/icons/speech-bubble.svg"
                  width="36"
                  class="inline-block"
                />
              </div>
              <p class="md:pt-5 text-lg w-4/5">Help &amp; Advice</p>
            </div>
          </a>
          <a
            href="https://www.dulux.com.au/colour"
            target="_blank"
            class="
              block
              bg-white
              p-4
              lg:py-6
              rounded-md
              mb-4
              sm:mb-0
              md:mb-2
              lg:mb-0
            "
            @click="(e) => $outbound(e)"
          >
            <div class="flex flex-no-wrap md:flex-wrap items-center">
              <div class="w-1/5 md:w-full">
                <img
                  src="@/assets/icons/colourshop.svg"
                  width="32"
                  class="inline-block"
                />
              </div>
              <p class="md:pt-5 text-lg w-4/5">Dulux Colour Shop</p>
            </div>
          </a>
          <a
            :href="linkAtBase('/how-to/choose-colour/test-visualise-colour/')"
            target="_blank"
            class="
              block
              bg-white
              p-4
              lg:py-6
              rounded-md
              mb-4
              sm:mb-0
              md:mb-2
              lg:mb-0
            "
            @click="(e) => $outbound(e)"
          >
            <div class="flex flex-no-wrap md:flex-wrap items-center">
              <div class="w-1/5 md:w-full">
                <img
                  src="@/assets/icons/visualiser.svg"
                  width="32"
                  class="inline-block"
                />
              </div>
              <p class="md:pt-5 text-lg w-4/5">How to Test Colour</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { linkAtBase } from "@/lib/links";

export default {
  data() {
    return {
      services: [],
    };
  },
  methods: {
    linkAtBase,
  },
};
</script>
