<template>
  <div class="mt-10">
    <div class="mb-2"><h3 class="text-xl">Upload files</h3></div>
    <VueDropzone
      id="dz"
      ref="dropzone"
      class="dz-component"
      :options="dropzoneOptions"
      :include-styling="false"
      :use-custom-slot="true"
      @vdropzone-sending="handleSending"
      @vdropzone-success="handleSuccess"
      @vdropzone-complete="handleComplete"
      @vdropzone-removed-file="handleRemovedFile"
    >
      <div class="dz-is-message">
        Click to select files, or drag and drop to upload. Max 2 MB each.
      </div>
    </VueDropzone>
    <p class="file-message mt-4">
      <strong>
        Please check the file upload is completed before you preview and submit.
      </strong>
    </p>
  </div>
</template>

<script>
import VueDropzone from "vue2-dropzone";
import EventBus from "@/eventbus";

export default {
  name: "App",
  components: {
    VueDropzone,
  },
  props: {
    dealId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      is_destroying: false,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_ENDPOINT}/file-upload/store`,
        params: () => {
          return {
            deal_id: this.dealId,
          };
        },
        createImageThumbnails: false,
        resizeWidth: 1920,
        paramName: "file",
        maxFilesize: 6, //mb,
        maxFiles: 10,
        acceptedFiles: "image/*,application/pdf",
        accept: this.checkAcceptableFile,
        autoDiscover: false,
        includeStyling: false,
        previewTemplate: this.getPreviewTemplate(),
        addRemoveLinks: true,
      },
    };
  },

  created() {
    EventBus.$on("uploaded_files_response", (payload) => {
      payload.forEach((file) => this.attachFile(file));
    });
  },

  beforeDestroy() {
    this.is_destroying = true;
  },

  methods: {
    attachFile(file) {
      console.log("attachFile");
      this.$nextTick(() => {
        this.$refs.dropzone.manuallyAddFile(
          { name: file.filename, size: file.size, serverUuid: file.uuid },
          file.url
        );
      });
    },
    getPreviewTemplate() {
      return `
                <div class="dz-preview">
                  <div class="dz-upload"><div data-dz-uploadprogress></div></div>
                  <div class="dz-preview__details">
                    <div class="dz-preview__left">
                      <span data-dz-name></span> <span data-dz-size></span>
                      <span class="dz-error-message" data-dz-errormessage></span>
                    </div>
                  </div>
              </div>
        `;
    },

    handleSending() {
      this.$store.commit("form/setNetworkProcessingFiles", true);
    },

    handleComplete(file) {
      this.$store.commit("form/setNetworkProcessingFiles", false);
      if (file.manuallyAdded) {
        console.log("addUploadedFile manuallyAdded", file);
        // this.$store.commit("form/addUploadedFile", file);
      }
    },

    handleSuccess(file, response) {
      if (response && response.data) {
        file.serverUuid = response.data.uuid;
        console.log("addUploadedFile handleSuccess", file, response.data);
        this.$store.commit("form/addUploadedFile", response.data);
      }
    },

    isDuplicateFile(file) {
      // New uploaded files
      const acceptedFiles = this.$refs.dropzone.getAcceptedFiles();
      // Previous uploaded files
      const filesWithStatus = this.$refs.dropzone.getFilesWithStatus();
      return (
        !!acceptedFiles.find((item) => item.name === file.name) ||
        !!filesWithStatus.find((item) => item.name === file.name)
      );
    },
    checkAcceptableFile(file, done) {
      if (this.isDuplicateFile(file)) {
        this.$refs.dropzone.removeFile(file);
        done("Duplicate file");
      } else {
        done();
      }
    },
    handleRemovedFile(file) {
      if (this.is_destroying) {
        return;
      }
      this.$store.commit("form/removeUploadedFileByUuid", file.serverUuid);
    },
  },
};
</script>

<style lang="scss">
#dz {
  @apply mb-6;
}

.dz-is-message {
  @apply p-4;
  @apply border-dotted;
  @apply border-2;
  @apply cursor-pointer;
}

.dz-preview {
  @apply my-2;
  @apply py-1;
  @apply px-2;
  @apply flex;
  @apply relative;
  @apply items-center;
  /* z-index: 1; */
}

.dz-remove {
  @apply ml-auto;
  @apply underline;
}

.dz-preview__left {
  @apply inline-flex;
  @apply items-center;
  @apply flex-1;
  > span {
    @apply mr-2;
  }
}
.dz-preview__right {
  @apply ml-auto;
}

.dz-preview__remove-button {
  @apply font-bold;
  @apply text-gray-800;
}

.dz-upload {
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply right-0;
  @apply bottom-0;
  z-index: -1;
  @apply bg-blue-100;
  @apply rounded;
  > div {
    @apply absolute;
    @apply top-0 left-0 bottom-0;
    @apply rounded;
  }
  .dz-error & {
    @apply bg-red-100;
    > div {
      @apply bg-red-300;
    }
  }
  .dz-success & {
    @apply bg-green-100;
    > div {
      @apply bg-green-300;
    }
  }
}

.dz-error-message {
  @apply relative;
  @apply text-xs;
  @apply text-red-500;
  @apply font-bold;
}
</style>
