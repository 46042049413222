<template>
  <div class="is-section bg-figma-5 print__no-break print__no-bg">
    <div class="container">
      <div class="columns">
        <div>
          <h2 class="text-2xl md:text-4xl">Images</h2>
          <p class="text">
            Click to download and view the image/s your designer has curated for
            your project planning.
          </p>
        </div>
        <div>
          <div class="files">
            <a
              v-for="(file, index) in files"
              :key="index"
              class="file"
              :href="file.url"
              target="_blank"
              @click="(e) => $outbound(e)"
            >
              <span class="file__icon">
                <IconDownload />
              </span>
              <span class="file__text"
                >{{ file.filename }} ({{ file.size_in_kb }}kb)</span
              ></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconDownload from "@/components/icons/Download";
export default {
  components: {
    IconDownload,
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
    dealId: {
      type: String,
      default: null,
    },
    files: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins.scss";

.columns {
  @include md {
    @apply grid;
    @apply grid-cols-2;
    @apply gap-8;
  }
}

.title {
  @apply mb-4;
  @include md {
    @apply mb-8;
  }
}

.text {
  @apply mb-4;
  max-width: 500px;
  @include md {
    @apply mb-8;
  }
}

.file {
  @apply flex;
  @apply py-1;
}

.file__icon {
  @apply mr-4;
}

.file__text {
  @apply underline;
  @include md {
    @apply text-lg;
  }
}

// Print styles
@media print {
  .print__no-bg {
    background: none;
  }
}
</style>
