<template>
  <div class="py-4">
    <validation-provider v-slot="{ errors }" rules="required">
      <label class="block my-2"
        >Consultation date<sup class="is-required">*</sup></label
      >
      <div class="relative w-40">
        <calendar
          class="
            absolute
            right-0
            top-0
            w-6
            mr-1
            mt-2
            pointer-events-none
            bg-white
          "
        />
        <v-date-picker
          v-model="consultationDate"
          class="date-picker rounded-none"
          mode="single"
          data="DD-MM-YYYY"
          :masks="{
            input: ['DD-MM-YYYY'],
          }"
          :popover="{ visibility: 'focus' }"
        />
      </div>
      <div class="is-error">{{ errors[0] }}</div>
    </validation-provider>
  </div>
</template>
<script>
import { ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import calendar from "../icons/Calendar";

extend("required", {
  ...required,
  message: "This field is required",
});
export default {
  components: {
    ValidationProvider,
    calendar,
  },
  computed: {
    consultationDate: {
      get() {
        return this.$store.state.form.data.consultation_date;
      },
      set(value) {
        this.$store.commit("form/setConsultationDate", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.date-picker {
  display: block;
}
</style>
