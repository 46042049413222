// import colors from "./master-colors.json";
import colors from "./colors-au.json";
import colorsNZ from "./colors-nz.json";
import products from "./products-au.json";
import productsNZ from "./products-nz.json";

import { isRegion } from "@/lib/region";

export default {
  colors: isRegion("nz") ? colorsNZ : colors,
  products: isRegion("nz") ? productsNZ : products,
};
