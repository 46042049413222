<template>
  <div v-if="dealData" class="relative">
    <ReportPreviewToolbar />
    <Report v-bind="previewReportData" />
    <ErrorNetworkModal
      v-if="isErrorNetworkModalDisplayed && !isUnstableConnectionModalDisplayed"
    />
    <ErrorUnstableConnectionModal v-if="isUnstableConnectionModalDisplayed" />
  </div>
</template>

<script>
import Report from "@/components/report/Report";
import ReportPreviewToolbar from "@/components/ReportPreviewToolbar";
import ErrorNetworkModal from "@/components/form/ErrorNetworkModal";
import ErrorUnstableConnectionModal from "@/components/form/ErrorUnstableConnectionModal";
export default {
  name: "ReportPreview",
  components: {
    Report,
    ReportPreviewToolbar,
    ErrorNetworkModal,
    ErrorUnstableConnectionModal,
  },
  computed: {
    dealData() {
      return this.$store.getters["deal/data"];
    },

    formData() {
      return this.$store.getters["form/formData"];
    },

    previewReportData() {
      return { ...this.dealData, ...this.formData };
    },

    isErrorNetworkModalDisplayed() {
      return this.$store.state.errorNetworkModalDisplayed;
    },

    isUnstableConnectionModalDisplayed() {
      return this.$store.getters["form/unstableNetwork"];
    },
  },

  mounted() {
    if (!this.dealData) {
      this.$router.replace("/");
    }
  },
};
</script>
