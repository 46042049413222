import Vue from "vue";
Vue.config.devtools = true;

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import api from "@/api.js";

import App from "@/App.vue";
import router from "@/router/index.js";
import store from "@/store/index.js";
import isRegion from "@/lib/region";

import VCalendar from "v-calendar";
import VueGtag from "vue-gtag";
import VueTextareaAutosize from "vue-textarea-autosize";

const enableSentry = false;

enableSentry &&
  Sentry.init({
    Vue,
    dsn: "https://69044b25c63d4c5c944da9e1ce3a90bf@o55969.ingest.sentry.io/5781150",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
});

Vue.use(VCalendar);
Vue.use(VueTextareaAutosize);

Vue.config.productionTip = false;

Vue.prototype.$api = api;
Vue.prototype.$isRegion = isRegion;

Vue.prototype.$outbound = (e) => {
  console.log(e);
  Vue.$gtag.event("click", {
    event_category: "outbound",
    event_label: e.target.getAttribute("href"),
  });
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
