<template>
  <div>
    <div v-if="message">
      {{ message }}
    </div>
    <div v-if="displayPage">
      <Form v-show="!isPreviewMode" :deal="dealData" :deal-id="dealId" />
      <FormPreview v-if="isPreviewMode" />
    </div>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";
import Form from "@/components/form/Form.vue";
import FormPreview from "@/components/form/Preview.vue";

export default {
  name: "FormPage",
  components: {
    Form,
    FormPreview,
  },
  props: {
    dealId: {
      type: String,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
    editId: {
      type: String,
      default: null,
    },
  },

  computed: {
    isPreviewMode() {
      return this.$store.state.previewMode;
    },

    displayPage() {
      return !this.network.error && !this.network.loading && this.dealData;
    },

    message() {
      return !Number(this.dealId)
        ? "Invalid ID"
        : this.network.loading
        ? "Loading..."
        : this.network.error
        ? this.network.errorMessage || "Error"
        : null;
    },

    network() {
      return this.$store.getters["deal/network"];
    },

    dealData() {
      return this.$store.getters["deal/data"];
    },

    dealRedirectRequested() {
      return this.$store.getters["deal/redirectRequested"];
    },
  },

  watch: {
    isPreviewMode() {
      window.scrollTo(0, 0);
    },
  },

  async mounted() {
    try {
      await this.getDealData();
    } catch (err) {
      Sentry.captureException(err);
      console.error("Error fetching deal:", err);
      return;
    }

    const redirectUri = this.dealRedirectRequested;

    if (redirectUri) {
      this.$router.push(redirectUri);
    } else {
      this.getEditableFields();
    }
  },

  methods: {
    togglePreviewMode() {
      this.$store.commit("togglePreviewMode");
    },

    async getDealData() {
      if (Number(this.dealId) && Number(this.contactId)) {
        const params = {
          dealId: this.dealId,
          contactId: this.contactId,
          editId: this.editId || null,
        };

        try {
          await this.$store.dispatch("deal/getById", params);
        } catch (err) {
          console.error("Error dispatching load action:", err);
        }
      } else {
        this.$store.commit("deal/resetData");
      }
    },

    getEditableFields() {
      if (Number(this.dealId) && Number(this.contactId) && this.editId) {
        this.$store.dispatch("form/getFormFieldsByEditId", {
          dealId: this.dealId,
          contactId: this.contactId,
          editId: this.editId,
        });
      }
    },
  },
};
</script>
