<template>
  <div class="is-section print__no-break">
    <div class="container">
      <h2 class="text-2xl md:text-4xl">{{ title }}</h2>
      <div class="area-row area-row--heading">
        <div class="area-row__item area-row__item--label">Area</div>
        <div class="area-row__item area-row__item--label">Colour</div>
        <div class="area-row__item area-row__item--label">Product</div>
      </div>
      <div v-for="(area, index) in areas" :key="index" class="area-row-wrapper">
        <div class="area-row">
          <div class="area-row__item">
            <div class="area-row__label">Area</div>
            <div class="area-row__text">{{ area.area }}</div>
          </div>

          <AreaColor :color-name="area.colour" />

          <AreaProduct :product-name="area.product" />

          <!-- <div class="area-row__item">
            <div class="area-row__label">Product</div>
            <img
              class="area-row__image"
              src="@/assets/images/dulux-product.png"
            />
            <div class="area-row__text">{{ area.product }}</div>
          </div> -->
        </div>
        <div v-if="area.note" class="area-row__item">
          <div class="area-row__label">Note</div>
          <div class="area-row__text whitespace-pre-wrap">{{ area.note }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AreaColor from "@/components/report/AreaColor";
import AreaProduct from "@/components/report/AreaProduct";
export default {
  components: {
    AreaColor,
    AreaProduct,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    areas: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins.scss";

.area-row-wrapper {
  @apply border-dashed;
  @apply border-b;
}

.area-row {
  @apply my-3;
  @include md {
    @apply my-4;
    @apply flex;
  }
}

.area-row__note {
  @apply text-sm;
  @apply my-3;
  @include md {
    @apply my-4;
  }
}

.area-row--heading {
  @apply hidden;
  @include md {
    @apply flex;
    @apply py-4;
    @apply my-4;
    @apply border-dashed;
    @apply border-b;
    @apply border-figma-0;
  }
}

.area-row__item {
  @apply flex;
  @apply justify-start;
  @apply items-center;
  @apply py-2;
  @apply pr-1;
  @include md {
    @apply py-1;
    flex: 1;
  }
}
.area-row__item--label {
  @apply font-display;
}

.area-row__label {
  @apply font-display;
  @apply mr-4;
  min-width: 60px;
  @include md {
    display: none;
  }
}

.area-row__color {
  @apply mr-4;
  @apply rounded-lg;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  background: #fff6f6;
  @include md {
    min-width: 76px;
    max-width: 76px;
    min-height: 76px;
    max-height: 76px;
  }
}

.area-row__text--color {
  min-height: 50px;
  @include md {
    min-height: 76px;
  }
}

.area-row__image {
  @apply mr-4;
  min-width: 50px;
  max-width: 50px;
  @include md {
    min-width: 72px;
    max-width: 72px;
  }
}
</style>
