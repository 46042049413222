<template>
  <div class="occ_accordion--wrap text-sm px-4">
    <div class="tab__header">
      <a
        href="#"
        class="flex justify-between pt-4 pb-2 px-0 md:px-2 text-dulux"
        @click.prevent="active = !active"
      >
        <span class="font-medium">{{ title }}</span>

        <CaretDown v-show="!active" class="-mt-1" width="18" />
        <CaretUp v-show="active" class="-mt-1" width="18" />
      </a>
    </div>
    <div v-show="active" class="tab__content nav--text">
      <slot />
    </div>
  </div>
</template>

<script>
import CaretUp from "../icons/CarretUp";
import CaretDown from "../icons/CarretDown";

export default {
  name: "Accordion",
  components: {
    CaretUp,
    CaretDown,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins.scss";

.text-dulux {
  @apply text-gray-100;
}

.nav--text {
    @apply text-gray-100;
}

.occ_accordion--wrap {
  border-top: 1px solid #e1ecf1;
}
</style>
